#top-head {
    background: #f22467;
    border-bottom: 1px solid #f22467;
    color: white;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    min-height: 39px;
    position: relative;
    z-index: 4;
    margin-bottom: 0px;
    width: 100%;
    letter-spacing: -0.13px;
    display:flex;
      
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($md) {
            min-height: 36px;
        }
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
            min-height: 30px;
    }
    
    
        a {
               color: white !important;
                        text-decoration: underline !important;;
        }
    
    .center {
        font-weight: 700;
        display: flex !important; 
        padding-left: 46px;
        padding-right: 46px;
        padding-top: 4px;
        padding-bottom: 4px;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        text-align: center;
    }
    
    .top-head-url {
        position: absolute;
        top: 11px;
        right: 30px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

                
        a {
            font-family: "Roboto", sans-serif;
            text-decoration: none !important;
            font-size: 13px;
            color: white !important;
            margin-right: 30px;  
            line-height: initial;
        }


        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($sm) {
            top: 7px;
        }


        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($xs) {
            display:none;
        }
        
        
        
    }
    
    .languages-block-top-main {
        cursor: pointer;
        padding-top: 10px;
        padding-right: 8px !important;

        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($sm) {
            padding-top: 6px !important;
        }

        
    }
    
    
    .top-head-lang {
        right: 12px;
        position: absolute;
        top: 0;
    }
    
    
    
    a {
        color:white;
        text-decoration:underline;
    }
    
    .container {
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        height: inherit;
        // width: 100%;
        
        &:before, &:after {
            content: initial;
        }
    }
}

.top-head-lang{
    position: absolute;
    top: 0;
    right: 9px;
    display: flex;
    align-items: center;
    @include breakpoint(lg) {
        right: 27px;
    }
    @include breakpoint($xs) {
        display: none;
    }
}

@include breakpoint($xs, $lg) {
    #top-head {
        .container {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

@include breakpoint($lg) {
    
    #top-head {
        .center {
            font-size: 12px;
            letter-spacing: -0.01em;
            line-height: normal;
        }
    }
}

// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
@include breakpoint($sm) {
    #top-head {
       // margin-top: -1px;
    }
}




@include breakpoint($sm) {



    #top-head .center {
        padding-left: 0;
        padding-right: 0;
    }
}


@include breakpoint($xs) {
    #top-head {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
        
        .center {
            font-size: 12px;
        }
    }
}
