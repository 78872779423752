#mini-cart {
    margin: 0;
    float: right;
    margin-left: 15px;
    margin-right: 2px;
    margin-top: 0px;
    display: inline-block;
    vertical-align: top;

    .cart-all-block {
        display: inline-block;
        position: relative;
    }

    .cart-icon {
        position: relative;
        left: -1px;
        margin-right: 10px;
    }


}


// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
@include breakpoint($md) {
    #top-right .top-right-url {
        font-size: 0px;
    }
}


// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
@include breakpoint($sm) {
    #mini-cart {
        margin-right: 0px;
    }

}


@include breakpoint($xs) {
    #mini-cart {
        margin-top: -1px  !important;
        margin-left: -2px  !important;
    }
}


.cart-icon-block {
    background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -8px -136px;
    width: 23px;
    height: 20px;
    display: inline-block;
    margin-bottom: -5px;
}

.quantity-bubbles {
    color: #fff;
    background: #f22467;
    border-radius: 50%;
    float: right;
    font-family: "Roboto", sans-serif;
    font-size: 9px;
    text-align: center;
    position: relative;
    top: -3px;
    left: 15px;
    float: none;
    width: 14px !important;
    height: 14px;
    display: block;
    position: absolute;
    line-height: 1.5;
}