@import '../../data';

.product_img_link {

  max-width: 290px;
  position: relative;
}


.mobile-filter-button {
  display: none;

  @include breakpoint(sm) {
    display: block;
  }
}

#enabled_filters {
  display: none;
}

//colors
$color_celeste_approx: #ccc;
$color_mantis_approx: #71bf47;
$white: #fff;
$color_azure_radiance_approx: #007bff;
$color_endeavour_approx: #0056b3;
$color_gallery_approx: #eee;
$color_silver_chalice_approx: #aaa;
$color_mountain_mist_approx: #999;
$color_cerulean_approx: #039be5;
$color_keppel_approx: #2ab7a9;
$color_boulder_approx: #787878;
$color_chicago_approx: #5a5a5a;
$color_jungle_green_approx: #26a69a;
$black_42: rgba(0, 0, 0, .42);
$color_shark_approx: #262626;
$color_pink_swan_approx: #b8b8b8;
$color_havelock_blue_approx: #5688d2;
$black: #000;
$color_marshland_approx: #0f0f07;
$color_bon_jour_approx: #e1e1e1;

//fonts
$font_0: 'brandon-regular';
$font_1: 'brandon-regular';
$font_2: 'brandon-regular';
$font_3: 'brandon-regular';
$font_4: 'brandon-regular';
$font_5: 'brandon_text_regularregular';
$font_6: 'brandon_text_regularregular';
$font_7: 'brandon_text_regularregular';
$font_8: 'brandon_text_regularregular';
$font_9: 'brandon_text_regularregular';
$font_10: 'brandon_text_regularregular';
$font_11: 'brandon_text_regularregular';
$font_12: 'brandon_text_regularregular';
$font_13: 'brandon_text_regularregular';
$font_14: 'brandon_text_regularregular';
$font_15: 'ralewaylight';
$font_16: 'ralewaylight';
$font_17: 'ralewaylight';
$font_18: 'ralewaylight';
$font_19: 'ralewaylight';
$font_20: 'brandon-bold';
$font_21: 'brandon-bold';
$font_22: 'brandon-bold';
$font_23: 'brandon-bold';
$font_24: 'brandon-bold';
$font_25: 'brandon_textbold';
$font_26: 'brandon_textbold';
$font_27: 'brandon_textbold';
$font_28: 'brandon_textbold';
$font_29: 'brandon_textbold';
$font_30: 'brandon_textbold';
$font_31: 'brandon_textbold';
$font_32: 'brandon_textbold';
$font_33: 'brandon_textbold';
$font_34: 'brandon_textbold';
$font_35: 'brandon-regular';
$font_36: 'brandon_text_regularregular';
$font_37: 'ralewaylight';
$font_38: 'sans-serif';
$font_39: 'ralewaylight';
$font_40: 'brandon-bold';
$font_41: 'brandon_textbold';


#layered_block_left {

  ul {
    max-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 1px;
    padding-right: 1px;

    li {
      margin-left: 15px;
      padding-left: 2px;
      list-style-type: none;

      &:hover {
        background: $color_mantis_approx;
        color: $white;
      }

      label {
        cursor: pointer;
      }

      &.nomargin {
        margin-left: 0;
      }

      input.checkbox {
        vertical-align: middle;
        margin-left: 2px;
      }

      &.second-level-cat {
        margin-left: 12px;
      }
    }
  }

  div.block_content {
    padding: 6px;
  }

  span.layered_subtitle {
    font-size: 16px !important;
    font-weight: 700 !important;
    display: block;
    margin-bottom: 4px;
    position: relative;
    letter-spacing: -0.5px;
  }
}

#layered_form {
  div div ul {
    max-height: 200px;
    overflow-x: auto;
  }
}


#layered_form {
  margin-right: 18px;

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    margin-right: 0px !important;
    margin-bottom: 150px;
  }




  // 	padding-bottom: 40px;
  > div {
    border-bottom: 1px solid $border;

    > div {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;

      > div {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  div div {
    ul {
      max-height: none;
      overflow: hidden;
    }

    ul::-webkit-scrollbar {
      display: none;
    }
  }

  input[type=checkbox] ~ label {
    margin-bottom: 16px;

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      float: none;
    }
  }
}

#layered_block_left {
  a {
    @include ff($F_MAIN_R);
    // @include ff($F_MAIN_R);
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
    color: $black2;
    font-size: 13px;
    font-weight: 400;

    &.product-type-list-active {
      @include ff($F_MAIN_B);
    }
  }

  ul {
    height: auto;

    li {
      padding-right: 0;
      display: flex;
      align-items: flex-end;
      max-height: initial !important;

      label {
        height: auto;
        padding-bottom: 0;
        line-height: inherit;
        vertical-align: top;
        margin-bottom: 6px;
      }

      &:hover {
        background: transparent;
      }

      input.checkbox {
        top: 0;
      }
    }
  }

  .layered_filter {
    a {
      width: 100%;
    }

    ul li label {
      display: inline-block;
      width: 100%;
    }
  }

  span.layered_subtitle {
    font-weight: 400;
    @include ff($F_MAIN_B);
    margin-top: 20px;
    color: $blackReal;
    width: 100%;
    padding-right: 30px;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    display: block;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($md) {
      font-size: 16px;
    }

  }

  div.block_content {
    padding-left: 0;
  }

  .lauyered-ul-wrap ul.layered_filter_ul {
    li {
      max-height: initial;
    }

    > li {
      padding-left: 0;

      li {
        padding-left: 28px;
        padding: 0;
        margin-left: 0;
      }
    }
  }
}

.close-mobile-filter {
  width: 100%;
  padding: 11px;
  @include ff($F_MAIN_B);
  font-weight: 600;
  color: $black;
  background: $white;
  border: none;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 9px;
  font-size: 20px;
  line-height: 24px;
  position: relative;
  letter-spacing: -1px;
}





.block {
  margin-bottom: 9px;
}

.stickas {

  max-width: 100%;
  width: 240px;
  @include breakpoint(sm) {
    width: 100%;
  }

}

.layered_filter {
  border-top: 1px solid $border;
}

input[type=checkbox] ~ label {
  padding-left: 21px;

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    padding-left: 27px;
  }


}

.second-level-cat {
  ul {
    display: block;
    max-height: none !important;
    width: 100%;
    padding-right: 0;
    overflow: visible !important;
  }

  input[type=checkbox] ~ label {
    padding-left: 20px;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
      padding-left: 25px;
    }


    &:after {
      width: 12px;
      height: 12px;
      top: 1px;
    }

    &:before {
      width: 5px;
      height: 9px;
      top: 2px;
      left: 0px;
      border-top: 1px solid #0000;
      border-left: 1px solid #0000;
    }
  }

  .ftext {
    margin-top: 0px;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
      line-height: inherit;
    }


  }
}

.pliusas {
  display: inline-block;
  background: url('/themes/default-bootstrap/dist/images/icons.svg') no-repeat -317px -11px;
  width: 16px;
  height: 16px;
  float: right;
  margin-top: 5px;
  position: absolute;
  right: 26px;
}

.layered_subtitle {
  cursor: pointer;
  border-top: 1px solid $border;
}

.loader-img {
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50px;
  background-color: transparent;
  display: block;
  background-size: 100% 100%;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.8;

  img {
    background: none !important;
  }
}

.nicescroll-rails {
  z-index: 999999999999 !important;
}

#left_column {

  flex: 0 0 17.666667%;
  max-width: 17.666667%;

  @include breakpoint(1450px) {
    flex: 0 0 24.777777%;
    max-width: 24.777777%;
  }

  @include breakpoint(sm) {
    display: none;
    &.open {
      display: flex;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      margin: 0;
      z-index: 102;
      background-color: white;
      overflow-x: scroll;

    }
  }

}


@media all and(-ms-high-contrast: none), (-ms-high-contrast: active) {
  .stickas {
    .ps__rail-y {
      display: none !important;
    }

    .ps__rail-x {
      display: none !important;
    }
  }
}






.show-left-column #left_column {
  margin-top: 23px;
}


.ftext {
  float: left;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
  margin-top: 2px;
}

body #layered_form div div {
  ul {
    padding-right: 5px;
    padding-bottom: 0;
    max-height: 410px;
    overflow-y: scroll;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
      max-height:none;
    }




    .second-level-cat {
      ul {
        @-moz-document url-prefix() {
          padding-right: 9px;
        }
      }
    }
  }
}

.layered_filter {

  .layered_subtitle:after {
    content: '';
    display: inline-block;
    background: url('/themes/default-bootstrap/dist/images/icons.svg') no-repeat -416px -15px;
    width: 13px;
    height: 13px;
    float: right;
    margin-top: 10px;
    position: absolute;
    right: 0px;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
      margin-top: 7px;
    }

  }

  &.open {
    .layered_subtitle:after {
      background: url('/themes/default-bootstrap/dist/images/icons.svg') no-repeat -435px -20px;
      width: 12px;
      height: 2px;
      margin-top: 15px;

      // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
      @include breakpoint($sm) {
        margin-top: 12px;
      }


    }
  }
}


//colors
$color_keppel_approx: #2ab7a9;
$color_boulder_approx: #787878;
$color_shark_approx: #262626;
$white: #fff;
$color_pink_swan_approx: #b8b8b8;

//fonts
$font_0: 'ralewaylight';
$font_1: 'ralewaylight';
$font_2: 'ralewaylight';
$font_3: 'ralewaylight';
$font_4: 'ralewaylight';
$font_5: 'brandon-regular';
$font_6: 'brandon-regular';
$font_7: 'brandon-regular';
$font_8: 'brandon-regular';
$font_9: 'brandon-regular';
$font_10: 'brandon_text_regularregular';
$font_11: 'brandon_text_regularregular';
$font_12: 'brandon_text_regularregular';
$font_13: 'brandon_text_regularregular';
$font_14: 'brandon_text_regularregular';
$font_15: 'brandon_text_regularregular';
$font_16: 'brandon_text_regularregular';
$font_17: 'brandon_text_regularregular';
$font_18: 'brandon_text_regularregular';
$font_19: 'brandon_text_regularregular';
$font_20: 'brandon-regular';
$font_21: 'brandon_text_regularregular';
$font_22: 'ralewaylight';
$font_23: 'sans-serif';
$font_24: 'ralewaylight';
$font_25: 'sans-serif';


.mfb-button {
  width: 100%;
  padding: 10px;
  line-height: 14px;
  font-size: 13px;
  height: 36px;
  color: $black;
  background: $white;
  border: 1px solid #d7d7d7;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 7px;

  span {
    line-height: 14px;
    font-size: 13px;
    @include ff($F_MAIN_B);
    color: $black;
    position: relative;
    display: inline-block;
font-weight: 600;
    &::before {
      content: " ";
      background: url("/themes/default-bootstrap/assets/images/filter_filters_funnel_l.svg") no-repeat;
      width: 21px;
      height: 15px;
      display: inline-block;
      position: relative;
      margin-bottom: -3px;
    }

  }

}

.mfb-button-active {
background:#f22467;
  border: 1px solid #f22467;
  span {
    color: #fff !important;
  }

  &:focus {
    background: #f22467 !important;
  }
span {
  &::before {
    mask-image: url("/themes/default-bootstrap/assets/images/filter_filters_funnel_l.svg");
    -webkit-mask-box-image: url("/themes/default-bootstrap/assets/images/filter_filters_funnel_l.svg");
    background-color: #fff !important;
    background:none;
    width: 15px;
    margin-right: 5px;
  }
}


}

.mobile-filter-bottom-block {
  display: none;

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    display: flex;
    position: fixed;
    bottom: 0px;
    box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    width: 100%;
    margin-left: -15px;
    padding-left: 15px;
    padding-top: 18px;
    padding-bottom: 18px;
    height: 80px;
    justify-content: space-between;
    padding-right: 15px;
  }

  button {
    height: 44px;
    border-radius: 4px;
    font-size: 13px;
  }

  .completed-filter-left {
    background: #ffff;
    border: solid 1px #d7d7d7;
    font-size: 13px;
    flex: 0 0 38%;
  }
  .completed-filter {
    background: #f22467;
    font-weight: 600;
    flex: 0 0 57%;
    color: #fff;
    border: 0px;
    border: solid 1px #f22467;
  }

}


.stickas-mobile-buttons {
  display: none;

  @include breakpoint(sm) {
    display: flex;
  }
}

.nbrItemPage {
  @include breakpoint(sm) {
    display: none !important;
  }
}


.ftextChild {
  overflow: hidden;
  width: 135px;
  padding-bottom: 1px;

  @-moz-document url-prefix() {
    padding-right: 5px;
  }


  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    width: auto;
  }

  &:hover {
    text-decoration: underline;
  }

}

#stickas {

  .ftextname {
    overflow: hidden;
    width: 140px;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
      width: auto;
    }
    padding-bottom: 1px;

    &:hover {
      text-decoration: underline;
    }

  }

  .filter-count {
    font-size: 10px;
  }

  [type="checkbox"] + label::after {
    height: 12px;
    width: 12px;
    top: 3px;
    border: 1px solid #d7d7d7;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
      height: 16px;
      width: 16px;
      top: 2px;
    }


  }


  [type="checkbox"]:checked + label::before {
    width: 5px;
    height: 8px;
    left: 1px;
    top: 5px;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
      width: 6px;
      height: 9px;
      left: 1px;
    }


  }

  [type="checkbox"]:checked + label:after, [type="checkbox"].checked + label:after {
    border: 1px solid #000000 !important;
  }

  .clearAll {
    color: #7a7c7c;
    font-size: 13px;
    text-decoration: underline;
    padding-top: 10px;
    padding-bottom: 10px;
    display: table;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
      text-decoration: none;
    }

  }

  .filter-ul-secound-level {
    padding-right: 0px !important;
  }


}

#filter-brand-search {
  border: 2px solid #d7d7d7 !important;
  padding-left: 10px;
  width: calc(100% - 56px);
  font-size: 13px;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    height: 26px;
  }



  &:focus {
    box-shadow: none !important;
  }

}

.filter-brand-search-icon {
  background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -116px -142px;
  width: 13px;
  height: 14px;
  display: inline-block;
  float: right;
  margin-top: -46px;
  margin-right: 13px;
}




