#search-popup-conteiner {
    
    //display: flex;
    display: none;
    position: relative;
    z-index: 999;
    right: 20px;
    .search-popup {
        position: absolute;
        box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.25);
        background: #fff;
        width: calc(100% + 40px);
        min-height: 450px;
        float: right;
        margin-top: 11px;
        z-index: 99999;
        padding-left:30px;
        padding-right:30px;
        padding-top:88px;
        margin-top: -74px;
        padding-bottom: 10px;
        border-radius: 4px;
        
        h3 {
            font-size: 22px;
            font-family: "brandon-bold","brandon_textbold";
            letter-spacing: -1.1px;
            margin-bottom:18px;
            
            // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
            @include breakpoint('1100px') {
                font-size: 18px;
            }
            
        }
        
        .search-product-list {
            display:none;
            
            .search-product-list-item {
                display:flex;
                margin-bottom: 10px;
                .search-product-list-img {
                    width:60px;
                    min-width:60px;
                    margin-right:10px;
                    display: block ruby;
                    img {
                        width:100%;
                    }
                }
                
                .search-product-list-text {
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    
                } 
            }
            
            a {
                color:#000;
                text-decoration:none;
            }   
            
        }
        
        .search-right {
            
            .category-list {
                display: none;
            }
            
            
            padding-left:60px;
            a {
                color:#000;
                text-decoration:none;  
                font-size: 13px;
                
                .in-category {
                    color:#a7a7a7;
                }
                
            }
            
            li {
                margin-bottom:10px;
            }
            
            ul {
                margin-bottom:45px;
            }
            
        }
    }  
    
    .did-you-mean {
        display:none;
        
        h3 {
            font-size: 18px;
            letter-spacing: -0.9px;
            
            // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
            @include breakpoint('1100px') {
                font-size: 16px;
            }
            
            
        }
        
        span {
            margin-right:15px;
            margin-bottom:5px;
            margin-bottom: 5px;
            display: inline-block;
            font-family: "brandon-regular","brandon_text_regularregular";
            font-size: 14px;
            
            a {
                font-size: 13px;
                color:#5688d2;
                
            }
            
            
            &:first-letter {
                text-transform: capitalize;
            }
            
        }
        margin-bottom:20px;        
    }
    
}

#search-block {
    width:660px;
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1100px') {
        width:502px;
    }
    
    #search_query_block {
        border:1px solid #b4b4b4;
        border-radius:0 !important;
        color: #5f5f5f;
        letter-spacing: -0.13px;
        font-size: 13px;
        
                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint($sm) {
                    font-size: 15px;
                }
                
        
        
        height: 40px;
        padding-left: 15px;
        -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint($sm) {
                        padding-right: 90px;
                }
                
        &:focus {
            box-shadow:none !important;
        }
        
    }
    
    .button-search {
        border: none;
        background: no-repeat;
        height: 38px;
        padding-right: 15px;
        
        .material-icons-search {
            background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -116px -142px;
            width: 13px;
            height: 14px;
            display: inline-block;   
            
                        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                        @include breakpoint($sm) {
                            background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -87px -138px;
                            width: 18px;
                            height: 18px;
                        }
                        
            
        }
    }
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
        position: absolute;
    }
    
}

#searchbox {
    z-index: 9999;
}
#search_query_block {
    
    transition: none;
    background: #fff;   
    
    &:focus {
        border:1px solid #4e89f3 !important;
    }
}

.search-overlay {
    opacity: 1;
    pointer-events: none !important;
    transition: opacity .3s ease;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 6;
    background-color: rgba(255, 255, 255, 0.5);
    display:none;
}

.loading-search {
    color: #000;
    font-weight: 400;
    font-size: 35px;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 50px;
    top: -6px;
    display: none;
    cursor: pointer;
}

.all-product {
    color: #5688d2 !important;
    font-size: 13px;
    float: right;
    position: relative;
    top: 8px;
    font-family: "brandon-regular", "brandon_text_regularregular" !important;
    
    
        &:hover {
            text-decoration: underline !important;
        }
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1100px') {
        top: 6px;
    }
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
        top: 1px;
        margin-right: 15px;
        font-size: 12px;
    }
    
}

.remove-search-input-val-mobile {
    color: #000;
    font-weight: 400;
    font-size: 35px;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 60px;
    display: none;
    cursor: pointer;
    margin-top: -34px;
    line-height: 1;
    width: 35px;
    height: 30px;
    text-align: center;
    
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($xs) {
            right: 53px;
            margin-top: -32px;
            height: 27px;
                right: 53px;
        }
        
    
}


.mobile-search {
    display:none;
    @include breakpoint($sm) {
        display:none !important;
    }
    position:absolute;
    
    background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -87px -138px;
    width: 18px;
    height: 18px;
    margin-top: 10px;
    margin-left: 50px;
    cursor: pointer;
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($xs) {
        margin-top: 5px;
    }
    
    
}


.mobile-close-search {
    position: absolute;
    right: 60px !important;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url(/themes/default-bootstrap/dist/images/icons.svg) no-repeat -281px -11px;
    width: 17px;
    height: 17px;
    display:none;
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($sm) {
             display: inline-block;
        }
        
   
    cursor: pointer;
}

.search-popup-conteiner-mobile {
    position: absolute;
    background: #fff;
    width: 100%;
    margin-left: -15px;
    padding-left: 25px;
    margin-top: 11px;
    padding-bottom:30px;
    display:none;
    box-shadow: 0px 2px 1px 0 rgba(0, 0, 0, 0.1), 0px 3px 6px 0 rgba(0, 0, 0, 0.1);
    
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($xs) {
            margin-left: 0px;
        }
        
    
    .did-you-mean-mobile {
        .mobile-item {
            display:block;
            a {
                font-size: 14px;
                width: 100%;
                color: #000;
                display: block;
                padding-bottom: 10px;
                
                
                &:first-letter {
                    text-transform: capitalize;
                }
                &:hover {
                    text-decoration:none;
                }
            }
        }
        
        .in-category {
            color: #a7a7a7;
        }
    }
    
    h3 {
        font-family: "brandon-bold","brandon_textbold";
        color:#b8b8b8;
        margin-top: 15px;
        margin-bottom: 20px;
        width:100%;
        font-size:13px;
    }
    
    .search-product-list-mobile {
        
        .search-product-list-item {
            display:flex;
            margin-bottom:15px;
            .search-product-list-img {
                width:60px;
                min-width:60px;
                img {
                    width:100%;
                }
                margin-right:15px;
            }
            
            .search-product-list-text {
                display: flex;
                align-items: center;
                justify-content: center;
                color:#000;
                font-size: 14px;
                padding-right:15px;
                text-decoration:none;
            }
            
        }
        a {
            &:hover {
                text-decoration:none;
            }            
        }
        
    }
    
    
}

#search-popup-conteiner .did-you-mean span a {
    font-size: 13px;
    color: #5688d2;
    
        strong {
            color: #5688d2;
        }
    
}


#search-block .button-search {
    border: none;
    background: no-repeat;
    height: 37px;
    padding-right: 15px;
}

// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
@include breakpoint($sm) {
    #search_block_left button {
    top: 11px;
}

#search-block .button-search .material-icons-search {
    background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -87px -138px;
    width: 18px;
    height: 18px;
        display: inline-block;
}

}

#search_block_left button {
    right: 3px;
    left: auto;
    top: 4px;
        position: absolute;
            border: none;
    background: no-repeat;
    height: 37px;
    padding-right: 15px;
}



// mobile search

#search_input_mobile {
    
    @include breakpoint($sm) {
        display:block;
    }
  
    @include breakpoint($xs) {
margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 29px);
    height: 28px;
    }
    border-radius:0px !important;
    display:none;
    border: solid 1px #b4b4b4;
    background-color: #ffffff;
    font-size: 12px;
    padding-left: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 33px;
    margin-top: 7px;
    margin-bottom: 0px;
    padding-bottom: 1px;
    padding-right: 44px;
    appearance: none;
    
    &:focus {
        border:1px solid  #4e89f3;
        box-shadow:none !important;
    }
    
}

.submitSearch {
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
        display:block;
    }
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($xs) {
        padding-top: 4px;
        padding-bottom: 4px;
            top: 72px;
    }
    
    
    display:none;   
    position: absolute;
    padding: 7px;
    float: right;
    right: 16px;
    top: 76px;
    padding-right: 10px;
    padding-left: 13px;
    cursor: pointer;  
    
    span {
        background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -116px -142px;
        width: 13px;
        height: 14px;
        display: inline-block;
        position: relative;
        top: 3px;
        
                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint($xs) {
                    top: 2px;
                }
                
        
    }
}

.mobile-search-load {
    display:none;
    background: url(/themes/default-bootstrap/img/loadingsearch.gif);
    width: 16px;
    height: 16px;
    position: absolute;
    right: 51px;
    top: 84px;
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($xs) {
                right: 48px;
                top: 76px;
        }
        
}