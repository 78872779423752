#signin-dropdown {
    position: absolute;
    z-index: 1000;
    float: left;
    font-size: 1rem;
    color: $black;
    text-align: left;
    list-style: none;
    overflow: hidden;
    background: transparent;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    width: 270px;
    margin: 0;
    .wrapper {
   
        position: relative;
        top: 15px;
        background-color: #fff;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 18px 18px 24px 18px;
        .signin-dropdown-top {
            width: 15px;
            height: 15px;
            display: block;
            transform: rotate(45deg) translate3d(50%, 100%, 0);
            background: #fff;
            position: absolute;
            top: -25px;
            left: 50%;
            box-shadow: 2px 2px 0px 2px #fff, 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
        }

        .button {
            height: 34px;
            text-transform: initial;
            color:white;
            @include ff($F_MAIN_R);
            font-weight: normal;
            background: $green;
            font-size: 13px;
            text-align: center;
            border-radius: 4px;
            border:none;
            line-height: 34px;
            padding: 0;
            width: 100%;
            margin-bottom: 5px;
            text-decoration: none;
            &:hover {
                background-color: $hoverGreen;
            }
            &.facebook, &.google, &.paypal {
                position: relative;
                &:before {
                    content: " ";
                    display: block;
                    position: absolute;
                    left: 8px;
                    top: 50%;
                    transform: translateY(-50%);

                    background-repeat: no-repeat;
                }
            }

            &.facebook {
                background: $facebook;
            }

            &.google {
                background-color: #fff;
                color: $black;
                letter-spacing: 0;
                border-radius: 4px;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25)!important;
            }
            &.paypal{
                color: white;
                background-image: -webkit-gradient(linear, left top, right top, from(#369ad7), to(#0f3b8a));
                background-image: -webkit-linear-gradient(left, #369ad7, #0f3b8a);
                background-image: -o-linear-gradient(left, #369ad7, #0f3b8a);
                background-image: linear-gradient(to right, #369ad7, #0f3b8a);
                -webkit-box-shadow: 0px 3px 13px 0 rgba(0, 0, 0, .19);
                box-shadow: 0px 3px 13px 0 rgba(0, 0, 0, .19);
                &:before {
                    width: 15px;
                    height: 17px;
                }
            }
        }

        p {
            text-align: center;
            margin: 0;
            color: $black;
            font-size: 13px;
            @include ff($F_MAIN_R);
        }

        a {
            color: $black;
            font-size: 13px;
            @include ff($F_MAIN_R);
            &.register {
                font-weight: bold;
                text-decoration: underline!important;
            }

            &.logout {
                font-weight: bold;
                text-decoration: underline!important;
            }
        }

    }
}


@include breakpoint($sm) {
    #my-login {
        margin-left: 0px;
        margin-right: 5px;
        margin-top: 0px;

    }
    #signin-link {
        top: 0;
        justify-content: flex-end;
        flex-wrap: nowrap;
        height: auto;
        flex-direction: row;
        align-items: center;
    }
}

@include breakpoint($xs) {
    #my-login {
        display: none !important;
    }
}
