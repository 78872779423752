
@font-face {
    font-family: 'brandon-bold';
//    src: url("/themes/default-bootstrap/assets/fonts/brandom_bold/brandon-bold.woff2") format("woff2"), url("/themes/default-bootstrap/assets/fonts/brandom_bold/brandon-bold.woff") format("woff");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'brandon-regular';
   // src: url("/themes/default-bootstrap/assets/fonts/brandom_regular/brandon-regular.woff2") format("woff2"),
   // url("/themes/default-bootstrap/assets/fonts/brandom_regular/brandon-regular.woff") format("woff"),
   // url("/themes/default-bootstrap/assets/fonts/brandom_regular/brandon-regular.svg#brandon_text_regularregular") format("svg");
    font-weight: 400;
}

@font-face {
    font-family: 'brandon_text_regularregular';
   // src: url('/themes/default-bootstrap/assets/fonts/brandonnew/brandontextregular-webfont.woff2') format('woff2'),
   // url('/themes/default-bootstrap/assets/fonts/brandonnew/brandontextregular-webfont.woff') format('woff'),
   // url('/themes/default-bootstrap/assets/fonts/brandonnew/brandontextregular-webfont.ttf') format('truetype'),
   // url('/themes/default-bootstrap/assets/fonts/brandonnew/brandontextregular-webfont.svg#brandon_text_regularregular') format('svg');

    //src: url("/themes/default-bootstrap/assets/fonts/Brandon_txt_reg-webfont.woff2") format('woff2'),
    //url("/themes/default-bootstrap/assets/fonts/Brandon_txt_reg-webfont.woff") format('woff'),
    //url("/themes/default-bootstrap/assets/fonts/Brandon_txt_reg-webfont.ttf") format('truetype'),
    //url("/themes/default-bootstrap/assets/fonts/Brandon_txt_reg-webfont.ttf") format('truetype');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'brandon_textbold';
  //  src: url("/themes/default-bootstrap/assets/fonts/Brandon_txt_bld-webfont.woff2") format('woff2'), url("/themes/default-bootstrap/assets/fonts/Brandon_txt_bld-webfont.woff") format('woff'), url("/themes/default-bootstrap/assets/fonts/Brandon_txt_bld-webfont.ttf") format('truetype');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'ralewaybold';
  //  src: url("/themes/default-bootstrap/assets/fonts/raleway-bold-webfont.woff2") format('woff2'), url("/themes/default-bootstrap/assets/fonts/raleway-bold-webfont.woff") format('woff'), url("/themes/default-bootstrap/assets/fonts/raleway-bold-webfont.ttf") format('truetype'), url("/themes/default-bootstrap/assets/fonts/raleway-bold-webfont.svg#ralewaybold") format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'ralewaylight';
   // src: url("/themes/default-bootstrap/assets/fonts/raleway-light.woff2") format('woff2'), url("/themes/default-bootstrap/assets/fonts/raleway-light.woff") format('woff'), url("/themes/default-bootstrap/assets/fonts/raleway-light.ttf") format('truetype'), url("/themes/default-bootstrap/assets/fonts/raleway-light.svg#ralewaylight") format('svg');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

