.glider-contain {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.productSlider {
    opacity: 0; /* Standard compliant browsers */
   -moz-opacity: 0; /* Firefox and Mozilla browsers */
   -webkit-opacity: 0; /* WebKit browser e.g. Safari */
   filter: alpha(opacity=0); /* For IE8 and earlier */
    
    height:300px;
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    
    h5 {
        text-align: center;
        @include breakpoint($xs) {
            display:none;
        }
    }
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @media screen and (max-width: 768px) {
        .content_price .price.product-price {
            font-size: 14px;
        }
        
        .right-block .content_price {
            margin-top: 7px;
        }
        
        .rb-pro-att {
            display:none;
        }
  
    }

    .product-brand-line {
        text-transform: uppercase;
        margin-top: 9px;
        text-align: center;
        font-family: "brandon-bold", "brandon_textbold";
        font-size: 13px;
    }  
    
    
    .content_price {
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    
    .product-list-review {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    
    .item-attributes {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    
    
    
    
}

.glider::-webkit-scrollbar {
    display: none !important;
}

.glider {
    margin: 0 auto;
    position: relative;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    transform: translateZ(0);
    scrollbar-width: none;
    
    a {
        -webkit-user-select: none;
        -webkit-user-drag: none;
        -webkit-app-region: no-drag;
        cursor: grab;
    }
    .color_to_pick_list  {
        justify-content: center !important;
    display: flex !important;
    }
        .product-container {
         margin-bottom: 15px !important; 

                    @include breakpoint($xs) {
                        margin-bottom: 10px !important; 
                    }
                    
            
    }

    .color-list-container.owl-carousel {
        max-width:100% !important;
    }
    
}
.glider-track {
    transform: translateZ(0);
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    z-index: 1;
}
.glider.draggable {
    user-select: none;
    cursor: -webkit-grab;
    cursor: grab;
}
.glider.draggable .glider-slide img {
    user-select: none;
    pointer-events: none;
}
.glider.drag {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}
.glider-slide {
    user-select: none;
    justify-content: center;
    align-content: center;
    width: 100%;
}
.glider-slide img {
    max-width: 100%;
}
.glider::-webkit-scrollbar {
    opacity: 0;
    height: 0;
}


.glider-prev,.glider-next {
    opacity:0;
    user-select: none;
    position: absolute;
    outline: none;
    background: none;
    padding: 0;
    z-index: 2;
    font-size: 40px;
    text-decoration: none;
    left: -23px;
    border: 0;
    top: 30%;
    cursor: pointer;
    color: #666;
    opacity: 1;
    line-height: 1;
    transition: opacity .5s cubic-bezier(.17,.67,.83,.67),
    color .5s cubic-bezier(.17,.67,.83,.67);

        @include breakpoint('1677px') {
            left: 0px;
            background: #fff;
                padding-top: 5px;
                padding-bottom: 0px;
                padding-right: 5px;  
        }

        img {
                vertical-align: baseline;
                }
        
/*    @include breakpoint($xs) {
                img {
                    width:70%;
                }
                padding-top: 0px;
                padding-bottom: 5px;
    }
    */
        
                @include breakpoint('949px') {
                    display:none;
                        }

}
.viewed-products-inner {
    .glider-prev,.glider-next {
        top: 81%;
            }
}
.viewed-products-no-recommended {
        .glider-prev,.glider-next {
        top: 57%;
            }
}

.recommended-products-no-viuwed {
           .glider-prev,.glider-next {
        top: 57%;
            } 
}


.glider-prev:hover,
.glider-next:hover,
.glider-prev:focus,
.glider-next:focus {
    color: #a89cc8;
    background: #fff;
}
.glider-next {
    right: -23px;
    left: auto !important;
    
        @include breakpoint('1677px') {
            right: 0px !important;
                background: #fff;
                padding-left: 5px;    
                padding-right: 0px;    
        }
    
}
.glider-next.disabled,
.glider-prev.disabled {
    
    color: #666;
    cursor: default;
    
        img {
            opacity: .25;
        }
    
}

.glider-slide {
    padding-left:10px;
    padding-right:10px;
    }
    
.glider-hide {
    opacity: 0;
}
.glider-dots {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
}
.glider-dot {
    background: none;
    border: 0;
    padding: 0;
    user-select: none;
    outline: none;
    display: block;
    cursor: pointer;
    color: #ccc;
    border-radius: 999px;
    background: #ccc;
    width: 12px;
    height: 12px;
    margin: 7px;
}
.glider-dot:hover,
.glider-dot:focus,
.glider-dot.active {
    background: #636363;
}
@media(max-width: 36em){
    .glider::-webkit-scrollbar {
        opacity: 1;
        -webkit-appearance: none;
        width: 7px;
        height: 3px;
    }
    .glider::-webkit-scrollbar-thumb {
        opacity: 1;
        border-radius: 99px;
        background-color: rgba(156, 156, 156, 0.25);
        box-shadow: 0 0 1px rgba(255,255,255,.25);
    }
}
