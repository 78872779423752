//colors
$color_azure_radiance_approx: #007bff;
$color_endeavour_approx: #0056b3;
$white: #fff;
$color_silver_chalice_approx: #aaa;
$color_tundora_approx: #444;
$color_suva_gray_approx: #888;
$color_cerulean_approx: #039be5;
$color_mint_tulip_approx: #c9f3ef;
$color_gallery_approx: #ededed;
$color_boulder_approx: #787878;
$white_90: rgba(255, 255, 255, .9);
$concrete: #f2f2f2;
$black: #000;
$black_42: rgba(0, 0, 0, .42);
$color_pink_swan_approx: #b8b8b8;
$color_havelock_blue_approx: #5688d2;
$color_iron_approx: #d7d7d7;

//fonts
$font_0: "Roboto", sans-serif;
$font_1: "Roboto", sans-serif;
$font_2: "Roboto", sans-serif;
$font_3: "Roboto", sans-serif;
$font_4: "Roboto", sans-serif;
$font_5: "Roboto", sans-serif;
$font_6: "Roboto", sans-serif;
$font_7: "Roboto", sans-serif;
$font_8: "Roboto", sans-serif;
$font_9: "Roboto", sans-serif;
$font_10: "Roboto", sans-serif;
$font_11: "Roboto", sans-serif;
$font_12: "Roboto", sans-serif;
$font_13: "Roboto", sans-serif;
$font_14: "Roboto", sans-serif;
$font_15: "Roboto", sans-serif;
$font_16: "Roboto", sans-serif;
$font_17: "Roboto", sans-serif;
$font_18: "Roboto", sans-serif;
$font_19: "Roboto", sans-serif;
$font_20: "Roboto", sans-serif;
$font_21: "Roboto", sans-serif;
$font_22: "Roboto", sans-serif;
$font_23: "Roboto", sans-serif;
$font_24: "Roboto", sans-serif;
$font_25: "Roboto", sans-serif;
$font_26: "Roboto", sans-serif;
$font_27: "Roboto", sans-serif;
$font_28: "Roboto", sans-serif;
$font_29: "Roboto", sans-serif;
$font_30: "Roboto", sans-serif;
$font_31: "Roboto", sans-serif;
$font_32: "Roboto", sans-serif;
$font_33: "Roboto", sans-serif;
$font_34: "Roboto", sans-serif;
$font_35: "Roboto", sans-serif;


.view-sort {
	display: none!important;
}
.content_sortPagiBar {
	height: 55px !important;

@include breakpoint($sm) {
	height: auto !important;
}




	.sortPagiBar {
		border-bottom: none;
		display: inline-block;
		vertical-align: top;
		#productsSortForm {
			width: auto;
			float: left;
			margin-right: 30px;
			margin-bottom: 30px;
			select {
				max-width: 192px;
				float: left;
				width: auto;

			}
		}
		label {
			padding: 0;
			font-size: 13px;
			margin-right: 6px;
			color: $blackReal;

			margin-bottom: 0;
			float: left;
		}
		.nbrItemPage {
			float: left;
			select {
				max-width: 59px;
				float: left;
			}
			.clearfix > span {
				display: inline-block;
				float: left;
			}
		}
		select {
			float: left;

		}
	}
	.heading-counter {
		display: inline-block;
		vertical-align: top;
		float: right;
		color: $grey2;
		margin-top: 4px;
	}
}
.nbrItemPage {
	.selector1 {
		width: 111px;
		position: relative;
		display: flex;
		align-items: center;
		&:after {
			position: absolute;
			content: '';
			background-image: url('https://klipshop.lt/themes/default-bootstrap/dist/images/select-down.png');
			background-repeat: no-repeat;
			right: 3px;
			width: 7px;
			height: 3px;
			pointer-events: none;
			top: 9px;
			left: calc(100% - 30px);
			left: calc(100% - 40px);
			display: none;
		}
		input {
			line-height: 22px;
			font-size: 16px;
			margin-top: 0;
		}
	}
	> div {
		padding-top: 0;
	}
}
#productsSortForm {
	> div {
		padding-top: 0;
	}
	.select {
		position: relative;
		display: flex;
		align-items: center;
		&:after {
			position: absolute;
			content: '';
			background-image: url('https://klipshop.lt/themes/default-bootstrap/dist/images/select-down.png');
			background-repeat: no-repeat;
			right: 3px;
			width: 7px;
			height: 3px;
			pointer-events: none;
			top: 9px;
			left: calc(100% - 30px);
		}
	}
}
.sortPagiBar {
	margin-top: 0;
	.select2 {
		min-width: 33px;
		margin-bottom: 0;
		max-width: 150px;
		.select2-selection--single {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 4px;
			height: 36px !important;
			border: solid 1px $grey1;
            position: relative;
			letter-spacing: -0.13px;
			font-size: 13px;
			@include ff($F_MAIN_B);
font-weight: 700;
			.select2-selection__rendered {
				line-height: 2.6em;
				font-size: 13px;
				padding-left: 8px;
				color: #000 !important;
			}

			.select2-selection__arrow b {
				border-color: #000 transparent transparent transparent;
				border-width: 4px 3px 0 3px;
			}
		}


	}


	.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
		border-color: transparent transparent #000 transparent;
		border-width: 0 3px 4px 3px;

	}

	.select2-container--default .select2-selection--single .select2-selection__arrow {
		height: 36px;
		position: absolute;
		top: 1px;
		right: 1px;
		width: 20px;
	}

}

#yahoo {
	 .select2 {
		min-width: 190px;
	}
}


.selectProductSort-hidden {
	display: inline-block;
	font-size: 16px;
	&.hidden {
		visibility: hidden;
		position: absolute;
	}
}
@media(max-width: 991px) {
	.content_sortPagiBar .sortPagiBar #productsSortForm select {
		max-width: 160px;
	}
}
@media screen and(max-width: 991px) {
	.content_sortPagiBar .sortPagiBar #productsSortForm {
		margin-right: 10px;
	}
}
@media only screen and(max-width: 767px) {
	.content_sortPagiBar .sortPagiBar .nbrItemPage {
		display: none;
	}
	#productsSortForm .select:after {
		left: calc(100% - 26px);
	}
	.nbrItemPage .selector1:after {
		left: calc(100% - 26px);
	}
}
@media(max-width: 479px) {
	.nbrItemPage .selector1 input {
		max-width: 146px;
		white-space: nowrap;
	}
}
@media(max-width: 374px) {
	.nbrItemPage .selector1 input {
		max-width: 127px;
		white-space: nowrap;
	}
}

[tabindex="-1"]:focus {
	outline: 0!important;
}
.select2-container--default .select2-selection--single {
	background-color: $white;
	border: 1px solid $color_silver_chalice_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 4px;
	.select2-selection__rendered {
		color: $color_tundora_approx;
		line-height: 28px;
	}
	.select2-selection__arrow {
		height: 26px;
		position: absolute;
		top: 1px;
		right: 1px;
		width: 20px;
		b {
			border-color: $color_suva_gray_approx transparent transparent transparent;
			border-style: solid;
			border-width: 5px 4px 0 4px;
			height: 0;
			left: 50%;
			margin-left: -4px;
			margin-top: -2px;
			position: absolute;
			top: 50%;
			width: 0;
		}
	}
}

.selectProductSort * {
	font-size: 13px;
}
body #productsSortForm .select:after {
	display: none;
}
