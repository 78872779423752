@import '../../data';

.footer-container {
    @extend %loading;

    &.loaded {
        @extend %loaded;
        overflow-x: hidden;
    }
}


