.product-card-banner {
    margin-right: auto;
    margin-left: auto;
    max-width: 1660px;
    max-height: 100px;
    text-align: center;
    padding: 0;
    //overflow: hidden;
    display: none;

    margin-top:15px;
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($md) {
            margin-top:10px;
        }
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
        margin-top:5px;
    }
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($xs) {
        margin-top:0px;
    }
    
    a {
        width: 100%;
        position: relative;
        display: inline-block;
        max-width: 1630px;
        padding-bottom: 2.454%;

        @include breakpoint(1024px) {
            padding-bottom: 4.024%;
        }

        @include breakpoint(xs) {
            padding-bottom: 10.177%;
        }
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        width: 100%;
    }

    @include breakpoint(1024px) {
        &.tablet {
            display: block;
        }
    }

    @include breakpoint(1025px, any) {
        &.desktop {
            display: block;
        }
    }

    @include breakpoint(xs) {
        &:not(.mobile) {
            display: none!important;
        }

        &.mobile {
            display: block;
            margin-bottom: 20px;
        }
    }
}
