@import "fonts.scss";

body #attributes {
  flex-basis: 100% !important
}

body {
  color: $black;
  font-size: 13px;
  // @include ff($F_MAIN_R);
  // &.fonts-loaded {
  //     font-family: nth(map-get($fonts, $F_MAIN_R), 1);
  // }

  font-family: nth(map-get($fonts, $F_MAIN_R), 1);
}

#page {
  overflow-x: hidden;
  max-width: 100%;


}

a, span, div, li {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

ul {
  list-style: none;
  padding-left: 0;
}

input, textarea {
  outline: none;
  width: 100%;
}

button, .button, a[href], .btn {
  &:hover, & {
    text-decoration: none;
  }
}

a, a:hover {
  color: $black;
}

button, .button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  white-space: normal;
  vertical-align: baseline;

  &.exclusive {
    height: 50px;
    width: 100%;
    color: rgb(255, 255, 255);
    background: rgb(38, 38, 38);
    font-size: 18px;
    text-align: center;
    border-radius: 4px;
    border: none;

    span {
      color: inherit;
      font-size: 16px;
      text-align: center;
    }
  }
}

img:not(.no-lazy) {
  transition: opacity 0.2s;

  &.lazy-loaded {
    opacity: 1;
  }

  &:not(.lazy-loaded) {
    //  opacity: 0;
  }
}

h1, h2, h3, h4, h5, .strong, strong {
  font-weight: normal;
  font-weight: 600;
  @include ff($F_MAIN_B);
  // @include ff($F_MAIN_B);
  color: $black;
}

img {
  text-indent: -9999px;
}

.icon {
  display: inline-block;
  zoom: 1.001;
  transform: scale(1.001);
}

.shopping_cart, #layer_cart {
  display: none;
}

.price {
  // @include ff($F_MAIN_B);
  @include ff($F_SECOND_B);
}

.owl-carouselx, .owl-carousel {
  margin-bottom: -10px;

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($xs) {
    margin-bottom: -20px;
  }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.hidden {
  display: none;
}

.close-iksas {
  top: 19px;
  cursor: pointer;
  transform: rotate(-45deg);
  width: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
}

.cyan {
  color: $cyan;
}

.promotion-img img:not(.no-lazy):not(.lazy-loaded) {
  opacity: 1 !important;
}

.select2-selection {
  outline: none;
}

.select2-container .select2-dropdown {
  border-radius: 0;
}

.spinner {
  margin: 0 auto;
  width: 80px;
  text-align: center;
  pointer-events: none;
}

.spinner .spinner__item1 {
  -webkit-animation-delay: -.3s;
  animation-delay: -.3s;
}

.spinner .spinner__item2 {
  -webkit-animation-delay: -.2s;
  animation-delay: -.2s;
}

.spinner > div {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.1s infinite ease-in-out both;
  animation: sk-bouncedelay 1.1s infinite ease-in-out both;
  margin-right: 5px;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

#columns {
  margin-top: 16px;
  @include breakpoint(lg) {
    padding-left: 30px;
    padding-right: 30px;
  }

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    padding-left: 15px;
    padding-right: 15px;
  }


  @include breakpoint(xs) {
    padding: 0 15px;
  }
}

#youtube-video-product {
  margin-bottom: 43px;
  position: relative;
  overflow: hidden;
  width: 930px;
  height: calc(100vw * 9 / 17.2);
  max-width: 100%;
  max-height: 523px;

  .resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

//safari < 11 hack

.delivery_info .logo {
  align-items: baseline !important;
}

.delivery_info .logo span {
  margin-top: 0;
}

.order_carrier_logo {
  display: block;
}

#shopswitcher .shop-options a[data-value="1"] span:before {
  top: 1px;
  left: -30px;
  position: absolute;
  content: '';
  width: 22px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/themes/default-bootstrap/assets/images/flags/lt.svg");
  display: inline-block;
}

#shopswitcher .shop-options a[data-value="1"] span {
  position: relative;
  display: block;
  margin-left: 29px;
}

.order_carrier_logo.carrier-logo-54, .order_carrier_logo.carrier-logo-12, .order_carrier_logo.carrier-logo-56 {
  background: url("/themes/default-bootstrap/assets/images/icons.svg") no-repeat -247px -112px;
  width: 35px;
  height: 27px;
}

.order_carrier_logo.carrier-logo-55, .order_carrier_logo.carrier-logo-48, .order_carrier_logo.carrier-logo-53 {
  background: url("/themes/default-bootstrap/assets/images/icons.svg") no-repeat -287px -112px;
  width: 33px;
  height: 27px;
}

#shopswitcher .shop-options a {
  display: block;
  min-width: 147px;
}

#shopswitcher {
  max-width: 129px;
}

#footer .shop-selector a {
  display: inline-block;
  width: auto;
}

#shopswitcher a {
  font-size: 13px;
  line-height: 14px;
  vertical-align: middle;
}

#shopswitcher a[data-value="1"]:lang(lt) span {
  position: relative;
  display: block;
  margin-left: 29px;
}

#shopswitcher a[data-value="1"]:lang(lt) span:before {
  top: 1px;
  left: -30px;
  position: absolute;
  content: '';
  width: 22px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/themes/default-bootstrap/assets/images/flags/lt.svg");
  display: inline-block;
}

#shopswitcher a[data-value="7"]:lang(et) span:before {
  top: 1px;
  left: -30px;
  position: absolute;
  content: '';
  width: 22px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/themes/default-bootstrap/assets/images/flags/ee.svg");
  display: inline-block;
}

#shopswitcher a[data-value="7"]:lang(et) span {
  position: relative;
  display: block;
  margin-left: 29px;
}

#shopswitcher a[data-value="4"]:lang(lv) span {
  position: relative;
  display: block;
  margin-left: 29px;
}

#shopswitcher a[data-value="4"]:lang(lv) span:before {
  top: 1px;
  left: -30px;
  position: absolute;
  content: '';
  width: 22px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/themes/default-bootstrap/assets/images/flags/lv.svg");
  display: inline-block;
}

#shopswitcher a[data-value="5"]:lang(en) span {
  position: relative;
  display: block;
  margin-left: 29px;
}

#shopswitcher a[data-value="5"]:lang(en) span:before {
  top: 1px;
  left: -30px;
  position: absolute;
  content: '';
  width: 22px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/themes/default-bootstrap/assets/images/flags/gb.svg");
  display: inline-block;
}

#shopswitcher .shop-options {
  display: none;
}

#shopswitcher .shop-options a {
  display: block;
}

.banner-wrapper img {
  image-rendering: auto;
}

.col-kuponas {

  display: none !important;
}

#shopswitcher a[data-value="7"]:lang(et) span {

  width: auto;
  display: inline-block;
}

.similar-product-list .price {
  @include ff($F_SECOND_R);

}

.pb-gallery:not(.initialized) {

  #product_gallery_thumbs {
    .product-gallery.thumb {
      //  display: none !important;
    }

    .product-gallery.thumb:nth-child(1) {
      display: block !important;
    }
  }
}

#module-gamintojai-gamintojas {
  #columns #center_column {
    margin: 0 auto;
  }

  #brendSimilar .brendSimilarinfo {
    float: left;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    @include breakpoint($xs) {

      justify-content: flex-start;
      text-align: center;
      align-items: center;
    }

  }

  #brendSimilar .brendSimilarinfo h1 {
    margin-bottom: 18px;
  }

  #brendSimilar .brendSimilarinfo h2 {
    margin-bottom: 18px;
    font-family: "raleway", sans-serif;
    font-weight: 300;
  }

  .brendSimilarinfoBase {
    margin-bottom: 80px;
  }

  #brendSimilar .brendSimilarTitile {
    margin-bottom: 40px !important;
  }

  .brendSimilarinfoBase {

    max-width: 1100px;
    margin: 0 auto;
  }

  #brendSimilar .brendSimilarinfobtn {

    margin-top: 0;

  }

  .brendSimilarinfoBase {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  @include breakpoint($xs) {
    #brendSimilar .brendSimilarinfo h1 {
      font-size: 40px !important;
      font-family: "releway", sans-serif;
      font-weight: bold;
    }
    #brendSimilar .brendSimilarinfo h2 {

      font-size: 21px;

    }
    .skalikas {
      height: auto !important;
    }
    .skalikas img {
      max-width: 100%;
      margin-top: 20px;
    }
  }
}

body .owl-stage-outer {
  .content_price {
    display: flex;
    justify-content: center;
  }

  .product-list-review {
    justify-content: center;
    display: flex;
  }

  .review-score-count {
    font-size: 11px;

    span {
      font-size: 11px;
    }
  }

  .product-brand-line {
    text-transform: uppercase;
    margin-top: 9px;
    text-align: center;
    @include ff(F_MAIN_B);
    font-size: 13px;
  }

  .rb-pro {
    .rb-pro-att {
      min-height: 30px;

      .color-list-container:not(.morethan7) {
        max-width: 100%;
      }

      .color-list-container:not(.morethan7) .color_to_pick_list {
        .owl-stage-outer {
          display: flex;
          justify-content: center;
        }
      }

      .owl-stage {
        display: flex;
        justify-content: center;

        .owl-item {
          display: flex;
          justify-content: center;
        }
      }
    }

    h5 {
      text-align: center;
    }

    .item-attributes {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;

      .icon.plus.md {

        margin-left: 5px;
      }
    }
  }

  .content_price {

    justify-content: center;

    .product-price {

      font-variant: lining-nums;
    }

    .price.product-price {
      font-size: 20px;
      @include ff($F_MAIN_B);
    }

    .old-price.product-price {
      font-size: 16px;
      @include ff($F_MAIN_R);
    }
  }

}

.icon.plus.md {
  background: url("/themes/default-bootstrap/dist/images/icons.svg") no-repeat -397px -16px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 11px;
  transform: rotate(-45deg);

  margin-left: 5px;

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint('1015px') {
    background: url("/themes/default-bootstrap/dist/images/icons.svg") no-repeat -506px -45px;
    width: 13px;
    height: 13px;
    margin-top: -6px !important;
    transform: rotate(0deg);
  }


}

@include breakpoint($xs) {

  .product-brand-line {
    text-transform: uppercase;
    margin-top: 18px;
    text-align: center;
    @include ff($F_MAIN_B);

    font-size: 12px;
  }
  .owl-carousel .owl-dots .owl-dot span,
  .owl-carouselx .owl-dots .owl-dot span {
    display: block;
    width: 6px;
    height: 6px;

    border: solid 1px #b7b7b7;
    border-radius: 50px;
  }
  #tabs .ui-widget-header li {

    margin-left: 5px;
    margin-right: 5px;

  }
  .ui-tabs-panel {
    min-height: auto;
  }
  body #recommended-products {
    margin-bottom: 40px;

    .owl-carouselx .owl-dots,
    .owl-carousel .owl-dots {
      position: relative;
      top: 4px;

    }
  }
  body .owl-stage-outer {
    .content_price .price.product-price {
      font-size: 14px;
    }

    .functional-buttons {

      //display: flex;
      //justify-content: center;
    }

    .right-block .content_price {

      margin-top: 7px;
    }

    .right-block .content_price {
      justify-content: center;
    }

    .rb-pro-att {
      display: none;
    }

    &:not(.s_title_block) {
      h5 {
        display: none;

      }
    }

    .s_title_block {
      h5 {
        margin: 0.3em 0em;
        display: block;

      }
    }

  }
  .s_title_block {
    h5 {
      margin: 0.3em 0em;
      display: block;

    }
  }
  body .owl-stage-outer .content_price .old-price.product-price {
    font-size: 11px;
    @include ff($F_MAIN_R);
  }

  #tabs .ui-widget-header li {

    margin-top: 0;
    margin-bottom: 0;

  }
  .owl-theme .owl-nav {
    display: none;
  }

  #tabs .ui-tabs-nav {
    list-style: none;
    margin-bottom: 27px;
  }
  .item-attributes-parent {
    .item-attributes {

    }
  }

  .owl-carouselx .owl-dots, .owl-carousel .owl-dots {
    position: relative;
    top: 5px;
  }
  #home-specials, #recommended-products, #home-news {
    h2.maintitle {
      margin-top: 0;
      margin-bottom: 22px;
      font-size: 18px;
      text-transform: uppercase;
    }

    .product-container {
      margin-bottom: 0;
    }
  }
  body #recommended-products, #home-specials {
    h2.maintitle {
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;
      padding-bottom: 16px;
    }
  }
}

@include breakpoint($xs) {
  #comments-module {
    height: auto !important;
    min-height: auto !important;
  }
}

.disklaimeris.uks {
  margin-top: 0;
  margin-bottom: 20px;
  background-color: #eef6ff;
  border-color: transparent;
  color: #5688d2;
  padding: .75rem 1.25rem;
  box-shadow: none;

  .disklaimeris-inner {
    color: #5688d2;
    font-family: Roboto,sans-serif !important;
    strong {
      color: #5688d2;
    }
  }

  .disklaimeris-close {
    display: none;
  }
}

#attributes {
  display: flex;
  margin-bottom: 0 !important;
  flex-wrap: wrap;

  & > div {
    flex-basis: 100%;
  }
}

.product_attributes {
  display: flex;

  #attributes:not(.so-single) {
    display: flex;
    margin-bottom: 30px;
  }
}

#out-of-stock-line:not(.hidden) {
  display: flex;
  flex-basis: calc(100% - 138px);

}

#out-of-stock-dis .disklaimeris.ofs {

  margin-top: 20px;


  box-shadow: none;


  padding: 17px 12px;
  color: #787878;
  background: #ededed;
  border-radius: 4px;
  width: 100%;
  font-size: 12px;

  .disklaimeris-inner {
    color: #787878;
    padding-left: 28px;
    position: relative;
    font-family: Roboto,sans-serif !important;
    &:before {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      background: url('/themes/default-bootstrap/assets/images/icons.svg') no-repeat -424px -39px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      left: 0px;
      top: -1px;
      opacity: 0.9;
    }
  }
}

@media only screen and (max-width: 992px) {
  body #out-of-stock-line:not(.hidden) {
    display: flex;
    flex-basis: 100%;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {

  body #last_quantities {
    margin-top: 0px;
  }
  #attributes {

    flex-basis: 100%;
    flex-wrap: wrap;
  }
  body #out-of-stock-line:not(.hidden) {
    flex-basis: calc(100% - 138px);
    margin-top: 0px;
  }
}

@media only screen and (max-width: 399px) {
  body #out-of-stock-line:not(.hidden) {
    flex-basis: 100%;
    margin-top: 12px;
  }
}

.pb-options h1 a:hover {

  text-decoration: underline !important;
  text-decoration-color: $black !important;
}

#attributes {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

body #recommended-products .product-container {
  margin-bottom: 10px;

}

body h2.maintitle {
  text-transform: uppercase;
}

@include breakpoint($xs) {
  #old_price, #old_price_display {
    font-size: initial;
    line-height: initial;

  }
}

div.color-list-container.morethan7 .owl-nav.disabled {
  display: none;
}

#color_to_pick_list .stage {

  display: block;
}

#attributes > div {
  flex-basis: 100%;
  max-width: 100%;
}

#review-pagination {
  display: flex;

  margin-top: 15px;

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($xs) {
    justify-content: center;
  }


}

.rate-comment {
  min-width: 75px;
}

@import "kaledos";
.rb-pro-att {
  .owl-carousel .color_to_pick_list .owl-stage-outer {
    align-items: center;
    display: flex;
  }
}

#product .deliveryTime {
  p {
    @include ff($F_MAIN_R);
    display: none;
  }

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($xs) {
    height: 37px;
  }


  margin-top: 3px;
  display: block;

  span {
    @include ff($F_MAIN_B);
  }
}

@media only screen and (max-width: 374px) {
  #product .deliveryTime {
    //deliveryTime   min-height: 40px;
  }
}

.plusfreeshipping {
  .infoIcon {
    width: 13px;
    height: 13px;
    background-image: url('/themes/default-bootstrap/assets/images/info_icon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    margin-left: 5px;
    display: inline-block;
    border-radius: 10px;
    /* display: flex; */
    line-height: 12px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
  }
}

body {
  a.product-logo {
    font-size: 18px;
    line-height: 1em;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 25px;
    display: block;
    @include ff($F_MAIN_B);
  }

  .pb-options h1 {

    @include ff($F_MAIN_R);
    letter-spacing: 0.5px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;

    margin-bottom: 15px;
  }
}


.product-info-left {
  .rating-top {
    display: flex;
  }
}

.product-info-left {
  .rating-wrapper {
    width: 78px;
    height: 18px !important;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .rating-symbol {

    height: 16px;
  }
}

.qr-head {
  br {
    display: none;
  }
}

.secondary_block.row #comments-module .qr-head .col-md-5 {
  padding-bottom: 0;
}

body #recommended-products .owl-carouselx .owl-dots, body #recommended-products .owl-carousel .owl-dots {
  position: relative;
  top: auto !important;
  bottom: 8px;
}

body #recommended-products {
  .owl-carouselx {
    margin-bottom: 0;
  }
}

// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
@include breakpoint($lg) {


  body {
    #header_logo .logo {
      width: 180px;
    }

    #header_logo a {
      width: 100%;
    }

    #header_logo {
      width: 180px;
      margin-top: 0;
    }
  }
}


@include breakpoint($md) {
  body {
    #header_logo .logo {
      left: 0px;

    }

    #header_logo {
      width: 180px;
      margin-top: 0;

    }
  }
}

// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
@include breakpoint($sm) {
  body {

    #header_logo {
      width: 165px;
    }
  }
}



// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
@include breakpoint($xs) {
  body {
    #header_logo .logo {
      left: 0px;
    }
  }
}

@include breakpoint($xs) {
  body .prod-wrap {

    flex-shrink: 0;
  }
}

.pb-gallery:not(.initialized) #product_gallery .product-gallery.init {

  max-height: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  transform: translateY(-50%);
}

body {
  #old_price, #old_price_display {
    line-height: 18px;
  }
}

.sortPagiBar .select2 .select2-selection--single .select2-selection__rendered {
  line-height: 29px;
}

@include breakpoint($xs) {
  .product-container {
    margin-bottom: 30px;
  }
}

#category {
  .layer_cart_overlay {
    display: none;
  }
}

.disableSelect {
  pointer-events: none;
  cursor: not-allowed;
}

#pagination_bottom {
  .page_li {
    cursor: pointer;
  }
}


.not-found-product {
  width: 100%;
  float: left;
  min-height: 500px;
  text-align: center;
  @include ff($F_MAIN_B);
  font-size: 14px;
  color: #e62b19;
}

.product-disable {
  opacity: 0.5;
}

.klipshop-pick-cat-container {
  text-align: center;

  .sub-cat-list {
    @include breakpoint($xs) {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
    }

  }

  .card {
    display: inline-block;
    padding: 8px 13px 8px;
    margin-right: 5px;
    border-radius: 18px;
    border: solid 1px #d7d7d7;
    letter-spacing: -0.13px;
    font-size: 13px;
    @include ff($F_MAIN_R);
    margin-bottom: 5px;

    &:hover {
      border: solid 1px #000;
      padding: 8px 13px 8px;
    }

  }

  .card-use {
    padding: 7px 12px 7px;
    border: solid 2px #000;
    font-weight: bold;
    letter-spacing: -0.4px;
  }

  .aproduct {
    background-color: #000;
    color: #fff;
    border: 0;
    font-weight: bold;
    letter-spacing: -0.13px;

    &:hover {
      border: 0;
      padding: 8px 13px 8px;
      letter-spacing: -0.13px;

    }

  }

}

.klipshop-pick-intro-text {
  margin: auto;
  max-width: 600px;
  text-align: center;
  letter-spacing: -.9px;
  font-size: 18px;
}

.cat-container {
  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    display: block;
  }

  @include breakpoint($xs) {
    display: none;
  }

  .sub-cat-list {
    //display:none;
    //visibility:hidden;
    margin-top: 0px;
    margin-bottom: 20px;
    //max-height: 43px;
    //overflow-y: hidden;
  }

  .more-show {
    white-space: nowrap;
    padding-top: 9px;
    margin-left: 15px;
    display: inline-block;

    &:first-letter {
      text-transform: capitalize;
    }

    > a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

    }

    .close {
      &:after {
        content: ' ';
        background: url(/themes/default-bootstrap/dist/images/icons.svg) no-repeat -456px -21px;
        width: 6px;
        height: 4px;
        display: inline-block;
        transition: 0.2s transform;
        margin-left: 3px;
      }
    }

    .open {
      &:after {
        content: ' ';
        background: url(/themes/default-bootstrap/dist/images/icons.svg) no-repeat -456px -21px;
        transform: rotate(180deg);
        width: 6px;
        height: 4px;
        display: inline-block;
        transition: 0.2s transform;
        margin-left: 3px;
      }
    }

  }

  .card {
    display: inline-block;
    padding: 8px 13px 8px;
    margin-right: 5px;
    border-radius: 18px;
    border: solid 1px #d7d7d7;
    letter-spacing: -0.13px;
    font-size: 13px;
    @include ff($F_MAIN_R);
    margin-bottom: 5px;

    &:hover {
      border: solid 1px #262626;
    }

  }

  .card-use {
    border: solid 1px #262626;
  }

  .hidden {
    display: none;
  }


}


#category-mobile-scrolling {

  display: none;

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($xs) {
    display: block;
  }


  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: -15px;

  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start;
    display: flex;

    &::-webkit-scrollbar {
      display: none;
    }

    .card {
      display: inline-block;
      padding: 6px 10px 6px 10px;
      background: red;
      margin-right: 5px;
      border-radius: 18px;
      background-color: #efefef;
      letter-spacing: -0.13px;
      font-size: 12px;
      @include ff($F_MAIN_R);
    }

    .card-use {
      opacity: 0.5;
    }


  }

  .scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .card {
      flex: 0 0 auto;
    }
  }

}

.klip-category-block {

  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 60px;
  max-height: 75px;
  overflow: hidden;

  .klip-category-block-inner {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  a {
    background-color: #efefef;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-top: 2px;
    line-height: 1.2;
  }

}


  .klip-category-block-more {
    display: none;
    text-decoration: underline;
    font-size: 13px;
    margin-top: 10px;
    cursor: pointer;
    position: relative;
    margin-bottom: 50px;
    &:hover {
      text-decoration: none;
    }

    &:after {
      content: ' ';
      background: url(/themes/default-bootstrap/dist/images/icons.svg) no-repeat -456px -21px;
      width: 6px;
      height: 4px;
      display: inline-block;
      position: absolute;
      margin-top: 12px;
      margin-left: 7px;
      transform: translateY(-50%);
    }

  }

