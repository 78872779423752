@import '../data';

//colors
$color_cadet_blue_approx: #aab2bd;

//original selectors
//button, input

%extend_1 {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: visible;
    color: inherit;
    font: inherit;
    margin: 0;
    background-image: none;
}

.sale-box {
    top: 4px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 50%;
    position: absolute;
    background: $black;
    height: 58px;
    width: 58px;
    left: -5px;
    right: auto;
    z-index: 2;
    text-decoration: none!important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    
    .sale-label {
        margin: 0;
    }
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1015px') {
        height: 44px;
        width: 44px;
    }
    
    
    
}

.price {
    &.product-price {
        @include ff($F_MAIN_B);
        color: $black;
        margin-bottom: 3px;
        display: block;
        font-size: 15px;
        font-weight: 700 !important;
        
    }
}

.product-brand-line {
    text-align: center;
    @include ff($F_MAIN_B);
    text-transform: uppercase;
    margin-top: 9px;
    font-size: 14px;
    font-weight: 700;
}

.item-attributes {
    @include ff($F_MAIN_R);
    // @include ff($F_MAIN_R);
    font-size: 13px;
    margin-top: -4px;
    margin-bottom: -1px;
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1015px') {
        margin-top: 0px;
    }

    display: flex;
    justify-content: center;
    
    a {
        
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint('1015px') {
            padding-top: 2.2px;
            padding-bottom: 2.2px;
            padding-left: 4px;
            padding-right: 4px;
            min-width: 64px;
        }
        
        
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 2px;
        display: inline-block;
        padding: 8px;
        color: $black;
        border: 1px solid $grey1;
        text-align: center;
        text-decoration: none;
        margin-right: 1px;
        line-height: 1em;
        margin-left: 0;
        //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
        transition: 0.3s;
        min-width: 84px;
        margin-bottom: 5px;
        
        &:hover {
            border: 1px solid $black;
            //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
            transition: 0.3s;
        }
    }
    
    .atrba {
        text-align: center;
        
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint('1015px') {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 22px;
        }
    }
}

.sale-label {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(0deg);
    width: auto;
    height: auto;
    color: $white;
    background: transparent;
    position: static;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    text-transform: initial;
    @include ff($F_MAIN_R_S);
    // @include ff($F_SECOND_L);
    margin-top: 11px;
    font-size: 16px;

    
    
    text-align: center;
    
    &:before {
        display: none;
    }
    
    &:after {
        display: none;
    }
}

.product-container {
    margin: 0 auto;
    position: relative;
    margin-bottom: 30px;
    max-width: 100%;
    width: 300px;
    
    .left-block {
        width: 100%;
        .product-image-container {
            position: relative;
        }
    }
    
    .sale-box {
        left: 2px;
        top: 6px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        
        .sale-label {
            margin: 0;
        }
    }
    
    
    .product-name {
        font-size: 12px;
        @include ff($F_MAIN_R);
        // @include ff($F_MAIN_R);
        line-height: 17px !important;
        text-decoration: none;
        display: block;
        font-weight: 300;
    }
}


.old-price {
    text-decoration: line-through;
    
    &.product-price {
        font-size: 15px;
        font-weight: 700;
        color: #f22467;
        
        @include ff($F_MAIN_R_S);
        display: block;
    }
}

.product_img_link {
    padding-bottom: 100%;
    width: 100%;
    display: block;
    outline: none!important;
    
    img {
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        image-rendering: -webkit-optimize-contrast;
        
    }
}

.right-block {
    h5 {
        margin-top: 8px;
        margin-bottom: 8px;
        line-height: 1.2em;
        font-size: 13px;
        text-align: center;
        
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint('1015px') {
            margin-top: 3px;
            margin-bottom: 3px;
        }
        
        
        
        a {
            font-size: 12px !important;
        }
        
    }
    
    .content_price {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: baseline;
        
        .price {
            margin-right: 6px;
        }
    }
}

.functional-buttons {
    margin-top:0px;
}

.rating-symbol-background {
    margin-right: 2px;
}

.rating-symbol {
    cursor: pointer;
}

.kainaAtributo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 12px;
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1015px') {
        font-size: 10px;
        padding-top: 1.2px;
        padding-bottom: 2.2px;
        padding-left: 4px;
        padding-right: 4px;
    }
    
}

.item-attributes-parent {
    padding: 0;
}

.content_price {
    //@include ff($F_SECOND_B);
    // @include ff($F_SECOND_B);
}

.product-brand-line {
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1015px') {
        margin-top: 3px;
    }
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($xs) {
        margin-top: 3px;
    }
    
}

#top-filters {
    a {
        @include breakpoint('1015px') {
            font-size: 12px !important;
        }
    }
    
}
.product_listas {

   .product-category-banner {
       width: 100%;
       height: auto;
   }

.rating-wrapper {


}


.review-score-count {
font-family: brandon-regular, brandon_text_regularregular, sans-serif;
    font-size: 10px  !important;
    padding-left: 1px;
    padding-right: 1px;
margin-left: -2px;
    line-height: normal !important;
        position: relative;
    top:-2px;
 
       
            @include breakpoint('1015px') {
                 top:-1px;
            }
            
    
    
    span {
        padding-left: 1px;
        padding-right: 1px;
    }
}

}

@media screen and(max-width: 1660px) {
    .item-attributes a {
        min-width: 70px;
    }
}
@media screen and(max-width: 1365px) {
    .item-attributes a {
        min-width: 68px;
    }
    
    .color-list-container.owl-carousel {
        // 		max-width: 255px;
    }
}

.rb-pro-att {

    min-height: 32px !important;
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1631px') {
        margin-top: 10px;
    }
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1015px') {
        min-height: 27px !important;
    }
    
    display: flex;
    justify-content: center;
    
        @include breakpoint($xs) {
        margin-top: 3px;
    }
    
    
}

@media only screen and(max-width: 767px) {
    .rb-pro-att {
        
        .owl-stage {
            margin-top: 4px;
        }
    }
    
    .right-block h5 {
        margin-top: 3px;
        margin-bottom: 3px;
    }
}


@media(max-width: 376px) {
    .item-attributes {
        a {
            padding: 2px;
        }
        
        .kainaAtributo {
            padding: 2px;
        }
    }
}

.product-list-review {

    
    .review-score-count {
        display: inline-block;
   
        @include ff($F_MAIN_R);
        // @include ff($F_MAIN_R);
     

    }
    
}

.new-box-width-sale {
    top: 75px !important;

   // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
   @include breakpoint($sm) {
       top: 60px !important;
   }


}

.new-box {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 50%;
    height: 58px;
    position: absolute;
    width: 58px;
    z-index: 2;
    background: #f22467;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    align-content: center;
    left: 0;
    top: 0;
    text-decoration: none!important;
    display: flex;
    span {
        margin: 0;
        padding: 0;
    }
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1015px') {
        height: 44px;
        width: 44px;
    }
    
    
}

.prezent-box {
    background: #efefef !important;
 
    .label-gift-txt {
        color: #000;
        font-size: 10px;
        top: 13px;
        position: relative;
        right: 1px;
        @include ff($F_MAIN_R);
        
                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint('1015px') {
                    font-size: 8px;
                }
                
        
    }
    
    .label-gift-icon {
        background: url("/themes/default-bootstrap/assets/images/giftbox.svg");
        width: 16px;
        height: 14px;
        position: relative;
        top: -16.5px;
        left: 11.5px;
        background-size: 16px 14px;
        
                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint('1015px') {
                    left: 11.5px;
                        top: -14.5px;
                }
                
        
    }
    
    
}

.have-more-label {
    margin-top: 65px;
    
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint('1015px') {
                margin-top: 55px;
        }
        
}

.new-label {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(0deg);
    width: auto;
    height: auto;
    color: $white;
    background: transparent;
    position: static;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    text-transform: initial;
    font-size: 12px;
    @include ff($F_MAIN_R_S);
    // @include ff($F_SECOND_L);
    margin-top: 11px;
    &:before {
        display: none;
    }
    &:after {
        display: none;
    }
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1015px') {
        font-size: 13px;
    }
    
    
}
.promo-block {
    border: 1px solid #ebebeb ;

    .promo-text {
            z-index: 1;
            position: absolute;
            font-family: brandon-bold,brandon_textbold,sans-serif;
            font-size: 8px;
            color: #b8b8b8;
            text-transform: uppercase;
            width: 100%;
            text-align: center;
            background-color: #fff;
            bottom: 3px;
    }
}
.klipshop-pick {
    color: $white;
    font-size: 13px;
    position: absolute;
    bottom: 10px;
    left: 0;
    text-align: center;
    justify-content: center;
    line-height: 1.38;
    letter-spacing: -0.65px;
    width: 105px;
    height: 20px;
    background: rgb(11,166,255);
    background: linear-gradient(90deg, rgba(194,29,255,1) 0%, rgba(11,166,255,1) 100%);
    cursor: help;
    //background-image: linear-gradient(to top, #0ba6ff, #c21dff);
    strong {
        color: $white;

    }
}
.klipshop-pick-b {
    width: 4px;
    height: 20px;
    position: absolute;
    bottom: 10px;
    left: 105px;
    border-right: 3px solid transparent;
    border-top: 20px solid rgb(11,166,255);



}
.color-list-container {
    
    
    .color-plus {
        font-family: $F_MAIN_R_S;
        font-size: 18px !important;
        font-weight: 700 !important;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #d7d7d7 !important;
        position: absolute;
        top: -3px;
            right: 5px;
        

        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($sm) {
            font-size: 18px !important;
            right: -14px;
            top: -4px;
        }

        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($xs) {
            top: -2px;
            font-size: 16px !important;
            right: -12px;
        }

        
        
    }
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1144px') {
        span:nth-child(5) {
            display: none;
        }
    }
    
    
}

.product-container {
    
    margin-bottom: 40px;
    padding-bottom: 30px;
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint('1015px') {
        margin-bottom: 0px;
    }
    
    .new-box {
        left: 2px;
        top: 6px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
}


.pb-left-column .prezent-box .label-gift-icon {
    background-repeat: no-repeat;
background-size: cover;
width: 36px;
height: 36px;
}

