//FONTS
$font-family-sans-serif: sans-serif;
$font-family-monospace: sans-serif;
$font-family-base: sans-serif;

$F_MAIN_R: F_MAIN_R;
$F_MAIN_B: F_MAIN_B;
$F_MAIN_R_S: "Roboto", "sans-serif", sans-serif;
$F_MAIN_B_S: "Roboto", "sans-serif", sans-serif;

$BrandonText: $F_MAIN_R;

$F_SECOND_B: F_SECOND_B;
$F_SECOND_L: F_SECOND_L;
$F_SECOND_R: F_SECOND_R;

$fonts: (
    F_MAIN_R: (#{"Roboto",sans-serif}, #{sans-serif}),
    F_MAIN_B: (#{"Roboto",  sans-serif}, #{sans-serif}),
    F_SECOND_B: (#{"Roboto",  sans-serif}, #{sans-serif}),
    F_SECOND_L: (#{"Roboto", sans-serif}, #{sans-serif}),
    F_SECOND_R: (#{"Roboto", sans-serif}, #{sans-serif}),
);

@mixin ff($font) {
    // .fonts-loaded & {
    //     font-family: nth(map-get($fonts, $font), 1);
    // }

    font-family: nth(map-get($fonts, $font), 1);
}

//Colors
$blackReal: #000000;
$white: #fff;
$black: #000;
$black2: #0f0f07;
$black3: #121212;
$blackAlt: #393939;
$blacHover: $blackAlt;

$red: #f44336;
$darkred: #ca2920;
$darkred2: #bd0000;

$blackAlt: #393939;
$close: #b5b5b5;
$grey1: #d7d7d7;
$grey2: #787878;
$grey3: #b8b8b8;
$grey4: #fcfcfc;
$grey5: #ededed;
$input-disabled-solid-color: #949494;
$grey8: #989898;
$grey9: #acacac;
$grey10: #d3d5d9;
$grey11: $grey9;
$grey12: #c4c4c4;
$grey13: #ebebeb;
$grey14: #a7a7a7;
$grey15: #757575;
$grey16: #f8f8f8;
$lcyan: #00a1d6;
$plus: #999b9b;
$border: #e1e1e1;
$divider: #ddd;
$placeholderN: #c4c4cb;

$breads: $grey2;

$filter: $grey5;
$golden: #d2aa62;
$cyan: #5688d2;

$cyan2: $cyan;
$cyan3: $cyan;
$newProduct: #73bdd5;

$lightGreen: #b9da8a;
$orange: #de4824;

$green: #41b35c;

$inputBB: $cyan;
$yellow: #feda58;
$yellowweird: #fbfad9;

$pink: #fa8686;
$pinkHover: #ea6e6e;

$google: #4885ed;
$facebook: #3c5a99;

$ahover: #262626;
$blue: #4285f4;

$orange: #de4824;
$orange2: #fe8209;

$filterCheckBoxBorder: $grey1;

$greyLightLine: $grey1;
$greyDarkLine: $grey2;
$greyLightText: $grey3;
$greyMediumText: $grey2;
$greyFooter: $grey4;
$greyHighlight: $grey5;

$registerButton: $cyan2;

// Responsive Rezoliucijos
$xs: 767px;
$sm: 991px;
$mm: 1024px;
$md: 1365px;
$lg: 1660px;

// input fields settings

$input-height: 24px;
$input-border-color: $grey5;
$input-border: 1px solid $input-border-color;
$input-background: #fff;
$input-error-color: $orange;
$input-success-color: $cyan;
$input-focus-color: $cyan;
$input-font-size: 16px;
$input-label-size: 16px;
$input-margin-bottom: 20px;
$input-margin: 0 0 $input-margin-bottom 0;
$input-padding: 3px 0px;
$input-transition: background-color 5000s ease-in-out 0s, box-shadow 0.3s, border 0.3s;
$input-disabled-color: rgba(0, 0, 0, .42);

$input-disabled-border: 1px dotted $input-disabled-color;
$input-invalid-border: 1px solid $red;
$placeholder-text-color: $grey2;
$input-textarea-padding: 5px 4px;
$input-textarea-resize: both;
$input-textarea-height: 62px;
$input-label-top: -8px;
$input-label-left: 0px;

$input-label-color: $grey2;
$input-field-margin-top: 1rem;

$input-label-top-active: -5px;
$input-label-scale-active: 0.8225;

%loading {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #fff;
        z-index: 1;
    }

    &:after {
        content: '';
        background-image: url('/themes/default-bootstrap/dist/images/369.gif');
        position: absolute;
        height: 48px;
        width: 48px;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
        z-index: 2;
    }
}

%loaded {
    pointer-events: all;
    height: auto;
    overflow: visible;

    &:after, &:before {
        content: initial;
        display: none;
    }
}

$container-max-widths: (
    sm: 100%,
    md: 100%,
    lg: 100%,
    xl: 1140px
) !default;

@function get_size($class) {
    $val: false;
    @if $class == xs {
        $val: $xs;
    } @else if $class == sm {
        $val: $sm;
    } @else if $class == md {
        $val: $md;
    } @else if $class == lg {
        $val: $lg;
    } @else if $class == any {
        $val: any;
    } @else {
        $val: $class;
    }

    @return $val;
}

@mixin breakpoint($min, $max: false) {
    $min_val: get_size($min);
    $max_val: get_size($max);
    @if $max_val == false {
        @media (max-width: $min_val) {
            @content;
        }
    } @else if $max_val == any {
        @media (min-width: $min_val) {
            @content;
        }
    } @else {
        @media (min-width: $min_val) and (max-width: $max_val) {
            @content;
        }
    }
}

@mixin placeholderColor($color) {

    &::-webkit-input-placeholder {
        color: $color;
    }
    &::-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
    &:-moz-placeholder {
        color: $color;
    }
}
