.starempty,
.starfilled,
.stargrey {
    background-repeat: no-repeat;
    @include ff($F_MAIN_R);
    // @include ff($F_MAIN_R);
    display: inline-block;
}

.stargrey {
    background-position: -50px -980px;
    width: 39px;
    height: 37px;
    
    &.medium {
        background-position: -150px -10px;
        width: 18px;
        height: 16px;
    }
    
    &.sm {
        background-position: -53px -6px;
        width: 21px;
        height: 20px;
    }
    &.mini {
        background: url(/themes/default-bootstrap/dist/images/icons.svg) no-repeat -101px -643px;
        width: 14px;
        height: 13px;
    }
}

.starempty {
    background-position: -6px -6px;
    width: 21px;
    height: 21px;
    
    &.medium {
        background-position: -114px -11px;
        width: 15px;
        height: 15px;
    }
    &.mini {
        background-position: -84px -644px;
        width: 13px;
        height: 12px;
        
    }
}

.starfilled {
    background-position: -3px -980px;
    width: 39px;
    height: 37px;
    
    &.sm {
        background-position: -29px -6px;
        width: 22px;
        height: 20px;
    }
    
    &.mini {
        background-position: -204px -14px;
        width: 13px;
        height: 12px;
        
    }
    
    &.medium {
        background-position: -132px -10px;
        width: 17px;
        height: 16px;
    }
}
@include breakpoint('1015px') {
    .ajax_block_product {
        
        .stargrey {
    
            background-position: -3px -1px !important;
            &.mini {
                background: url(/themes/default-bootstrap/dist/images/star_mini.svg) no-repeat;
                width: 11px;
                height: 10px;
            }
        }
        
        
        .starfilled {
            &.mini {
                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                
                width: 11px;
                height: 10px;
                background-position: -17px -1px;
                
            }
        }
    }
}

.rating-symbol-background {
    margin-right: 2px;
}

#product {
    
  .review-score-count {
    margin-top: 2px;
    margin-left: 5px;
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    padding-left: 1px;
    padding-right: 1px;
    line-height: 1.4em;
    font-size: 11px;
}
  
    .product-footer {
        .review-score-count {
            margin-top: 1px;
margin-left: 1px;
                    }
    }

}


.rateus {
    text-decoration: underline;
}
