h1.page-heading {
    font-weight: 300;
}
#module-gamintojai-gamintojas{
    #columns #center_column {

        margin-left:  auto;
        right:  auto;
    }
}
#module-klipshop_pick-pick{
    #columns #center_column {
        margin: auto;
    }
    .klipshop-pick-cat-container {
        margin: 20px 0 60px ;
        @include breakpoint($sm) {
            margin: 15px 0 25px ;

        }
    }
    #productsSortForm {
        @include breakpoint($sm) {
            width: 100% !important;

        }

    }
    .mobile-filter-button {
        display: none;
    }
    .img-mob {
        width: 100%;
        height: auto;

        display:none;
        @include breakpoint($xs) {
            display:block;
        }
    }

    .img-desk {
        @include breakpoint($xs) {
            display:none;
        }

        width: 100%;
        height: auto;

    }


}

#brendSimilar{
    .ajax_block_product {

        flex-basis:100%;
        max-width: 100%;
    }
    .owl-carousel {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        overflow: hidden;

        &:not(.owl-loaded) {
            > *:not(.owl-stage):not(.owl-stage-outer):not(.color_to_pick_list) {
                flex-basis: 25%;
                flex-grow: 0;
                flex-shrink: 0;
                width: 25%;
            }
        }

        &.owl-loaded {
            flex-wrap: wrap;
            .owl-dots {
                flex-grow: 1;
                text-align: center;
                justify-content: center;
                display: flex;
            }
        }

    }


    .owl-carouselx {
        padding-left: 5px;
        padding-right: 5px;
    }


}





#brendSimilar .brendSimilarTitile {
    font-size: 24px;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    text-transform: inherit;
    margin-bottom: 0 !important;
    width: 100%;
}

#brendSimilar .skalikas {
    width: 578px;
    height: 460px;
    display: block;
    float: right;
}

#brendSimilar .brendSimilarinfo h1 {
    font-size: 49px !important;
    font-family: "Raleway", sans-serif;
    font-weight: 700;

}

#brendSimilar .brendSimilarinfo h2 {
    font-family: "Raleway", sans-serif;
    font-size: 30px;
    font-weight: 300;
    margin-top: -24px;
}

#brendSimilar .brendSimilarinfo {
    float: left;
    margin-top: 70px;
}

#brendSimilar .brendSimilarinfobtn {
    height: 50px;
    width: 280px;
    color: white;
    background: #262626;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    border: none;
    float: left;
    padding-top: 14px;
    margin-top: 25px;
    text-decoration: none !important;
}

.brendSimilarinfoBase {
    display: flex;
    justify-content: space-between;
}

.brendSimilarinfobtnres {
    display: none;
}

.col-kuponas {
    display: block !important;
    border: none !important;
    box-shadow: none !important;
}

#footer:not(:lang(lt)) #footer-fields .col-profesional:before {
    display: none !important;
}

.owl-carousel {
    position: relative;
    display: none;
    width: 100%;
    z-index: 1;
    display: block;
    // padding-left: 90px;
    // padding-right: 90px;
    margin-bottom: -20px;
    .owl-item {
        flex-shrink: 0;
        flex-grow: 0;
        position: relative;
        min-height: 1px;
        float: left;
        img {
        }
    }
    .owl-stage {
        position: relative;
        touch-action: manipulation;
        &:after {
            content: ".";
            display: block;
            clear: both;
            visibility: hidden;
            line-height: 0;
            height: 0;
        }
    }
    .owl-stage-outer {
        position: relative;
        overflow: hidden;
        width: 100%;
        flex-basis: 100%;
    }
    .owl-dots {
        position: relative;
        top: -20px;
        &.disabled {
            display: none;
        }
        .owl-dot {
            span {
                background: none;
                background-image: url('/themes/default-bootstrap/dist/images/sliderbulletempty.png');
                background-repeat: no-repeat;
                background-position: center center;
                margin: 5px;
                display: block;
                width: 8px;
                height: 8px;
            }
            &.active span {
                background: none;
                background-image: url('/themes/default-bootstrap/dist/images/sliderbulletfull.png');
                background-repeat: no-repeat;
                background-position: center center;
            }
            &:hover span {
                background: none;
                background-image: url('/themes/default-bootstrap/dist/images/sliderbulletfull.png');
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
    .owl-nav {
        &.disabled {
            display: none;
        }
        .owl-next {
            cursor: pointer;
            cursor: hand;
            //Instead of the line below you could use @include user-select($select)
            user-select: none;
            position: absolute;
            top: 130px;
            background-color: transparent;
            border: 0;
            //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
            border-radius: 0;
            background-repeat: no-repeat;
            height: 35px;
            width: 18px;
            background: none;
            color: transparent;
            cursor: pointer;
            right: 0;
            //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
            transform: rotate(180deg);

            span[aria-label] {
                background: url('/themes/default-bootstrap/dist/images/icons.svg') no-repeat -279px -470px;
                width: 18px;
                height: 33px;
                display: inline-block;
                color: transparent;
                display: none!important;
            }
            &.disabled {
                opacity: 1;
            }
        }
        .owl-prev {
            cursor: pointer;
            cursor: hand;
            //Instead of the line below you could use @include user-select($select)
            user-select: none;
            position: absolute;
            top: 130px;
            background-color: transparent;
            border: 0;
            //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
            border-radius: 0;
            background-repeat: no-repeat;
            height: 35px;
            width: 18px;
            background: none;
            color: transparent;
            cursor: pointer;
            left: 0;

            span[aria-label] {
                background: url('/themes/default-bootstrap/dist/images/icons.svg') no-repeat -279px -470px;
                width: 18px;
                height: 33px;
                display: inline-block;
                color: transparent;
                display: none!important;
            }
            &.disabled {
                opacity: 1;
            }
        }
        button {
            &.owl-next {
                background: 0 0;
                color: inherit;
                border: none;
                padding: 0!important;
                font: inherit;
            }
            &.owl-prev {
                background: 0 0;
                color: inherit;
                border: none;
                padding: 0!important;
                font: inherit;
            }
        }
    }
    &.owl-loaded {
        display: block;
    }
    .owl-dot {
        cursor: pointer;
        cursor: hand;
        //Instead of the line below you could use @include user-select($select)
        user-select: none;
    }
    button.owl-dot {
        background: 0 0;
        color: inherit;
        border: none;
        padding: 0!important;
        font: inherit;
    }
    &.owl-drag .owl-item {
        touch-action: auto;
        //Instead of the line below you could use @include user-select($select)
        user-select: none;
    }
}
.owl-carouselx {
    margin-bottom: -20px;
    .owl-nav {
        .owl-prev {
            position: absolute;
            top: 130px;
            background-color: transparent;
            border: 0;
            //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
            border-radius: 0;
            background-repeat: no-repeat;
            height: 35px;
            width: 18px;
            background: none;
            color: transparent;
            cursor: pointer;
            left: 0;

            span[aria-label] {
                background: url('/themes/default-bootstrap/dist/images/icons.svg') no-repeat -279px -470px;
                width: 18px;
                height: 33px;
                display: inline-block;
                color: transparent;
                display: none!important;
            }
            &.disabled {
                opacity: 1;
            }
        }
        .owl-next {
            position: absolute;
            top: 130px;
            background-color: transparent;
            border: 0;
            //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
            border-radius: 0;
            background-repeat: no-repeat;
            height: 35px;
            width: 18px;
            background: none;
            color: transparent;
            cursor: pointer;
            right: 0;
            //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
            transform: rotate(180deg);

            span[aria-label] {
                background: url('/themes/default-bootstrap/dist/images/icons.svg') no-repeat -279px -470px;
                width: 18px;
                height: 33px;
                display: inline-block;
                color: transparent;
                display: none!important;
            }
            &.disabled {
                opacity: 1;
            }
        }
    }
    .owl-dots {
        position: relative;
        top: -20px;
        .owl-dot {
            span {
                background: none;
                background-image: url('/themes/default-bootstrap/dist/images/sliderbulletempty.png');
                background-repeat: no-repeat;
                background-position: center center;
                margin: 5px;
                display: block;
                width: 8px;
                height: 8px;
            }
            &.active span {
                background: none;
                background-image: url('/themes/default-bootstrap/dist/images/sliderbulletfull.png');
                background-repeat: no-repeat;
                background-position: center center;
            }
            &:hover span {
                background: none;
                background-image: url('/themes/default-bootstrap/dist/images/sliderbulletfull.png');
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
}
@media(max-width: 992px) {
    .owl-carouselx {
        padding-left: 30px;
        padding-right: 30px;
    }
    .owl-carousel {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media(max-width: 767px) {
    .owl-carouselx .owl-nav {
        .owl-prev span[aria-label] {
            display: none;
        }
        .owl-next span[aria-label] {
            display: none;
        }
    }
    .owl-carousel .owl-nav {
        .owl-prev span[aria-label] {
            display: none;
        }
        .owl-next span[aria-label] {
            display: none;
        }
    }
}

body #brendSimilar .brendSimilarTitile {

    margin-bottom: 0px !important;

}
