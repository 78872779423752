@import '../../data';

@import './topHead';
// @import './mobileHeader';
@import './search';
@import './user';
@import './miniCart';
@import './navigation';

#header{
    margin-top: 24px;
}

.love-yourserf {
    width: 165px;
    height: 9px;
    background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -8px -169px;
    margin-left: -140px;
    margin-top: 13px;
    margin-right: -45px;
}

#header_logo {
    width: 180px;

    .img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }

}


.breadcrumb {
    font-weight: 400;
    span,a {
        font-weight: 400;
    }
}

.final-menu{
    margin-bottom: 60px;
}

.pb-left-column #image-block .new-box, .pb-left-column #image-block .sale-box {
    z-index: 0;
}

#languages-block-top div.current {

    text-shadow: none;
    color: #fff;
}


#top-right{
        display: flex;
        line-height: 1.5;
}

.top-right-url {
    color: #000;
    font-size: 12px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

#header {
     padding-top: 30px;
    margin-bottom: 0;
    height: 122px;
    border-bottom: 1px solid #f5f5f5;
    margin-top: 0;
    position: relative;


    .container {
    padding-left: 15px;
    padding-right: 15px;
}
    
}

@media (max-width: 1365px) and (min-width: 768px) {
    #columns {
        padding-left: 30px;
        padding-right: 30px;
    }
}

#languages-block-top {
    border:none;
}

#languages-block-top div.current {
    padding:0;
    text-transform: uppercase;
}

#languages-block-top ul {
    width:100%;
}

#languages-block-top ul li a, #languages-block-top ul li > span {
    padding: 0 10px 0 12px;
    display: block;
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
}

#languages-block-top ul li a, #languages-block-top ul li > span {

    text-transform: uppercase;
    padding: 0px;
    padding-left: 5px;
    vertical-align: top;
    display: inline-block;
    width: 100%;
}

#languages-block-top div.current{
    padding:5px;
    span{
        font-size: 13px;
        margin-top: 8px;
        line-height: 14px;
        vertical-align: top;
        display: inline-block;
    }
    &:after {
        font-size: 10px;
        line-height: 18px;
        color: #ffffff;
        padding-left: 3px;
        vertical-align: -5px;
    }
}
#languages-block-top ul {

    background: $blackReal;

    li:hover,   li:hover a{
        background: $blackReal
    }

}

#languages-block-top{
    img{
        margin: 0;
        border-radius:50px;
        width:22px;
        height: 22px;

        margin-right: 2px;
        vertical-align: top;
        margin-top: 5px;
    }
}

#first-languages{
    a{
        text-decoration: none;
    }
    span{
        display: inline-block;
        line-height: 32px;
    }
}

.sf-menu > li > .menu-item-wrap{
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.34);
}

.side-nav li>a {

    font-weight: 500;
    height: 1.2em;
    line-height: 1.2em;
}

.side-nav li>a {
    font-weight: 400;
    height: 1.2em;
    line-height: 1.3em;
    padding: 0px 20px;
}

#header {


    z-index: 9;

}


#first-languages{
    opacity: 1;
    background:white;
    li.selected{
        display: none!important;
    }
    span{
        color:$black;
    }
}

#languages-block-top ul {
    background: #fff;
    top: 40px;
    position: absolute;

    left: -10px;
    padding: 4px 0px;

    width: 88px;

    z-index: 2;
    min-width: 85px;
    span{
        font-size: 13px;
        color: rgba(0,0,0,0.87);
    }
}
.dropdown-content li {

    min-height: 20px;
}
#languages-block-top ul li:hover a {
    background: transparent;
}
#languages-block-top ul li:hover {
    background-color: rgba(0,0,0,0.06);
}
#languages-block-top ul li {
    color: #fff;
    line-height: 35px;
    font-size: 13px;
    padding: 2px 10px;
}
.sf-menu {
    & > li {
        & > a {
            padding-bottom: 30px;
        }
    }
}

.main-title-home{
    margin-bottom: 0;
}
.row {

    margin-bottom: 10px;
}
.new-box, .sale-box {
    top:4px;
}
.prisijungimasMobilus {
    top: -4px;
}
.kontRespMeniu {
    top: -2px;
}
.krepsRespMeniu {
    float: left;
    width: 23px;
    height: 20px;
}
.kontRespMeniu, .prisijungimasMobilus {
    float: left;
    width: 23px;
    height: 23px;
    position: relative;
}
.prisijungimasNuoroda a {
    color: $black;
    @include ff($F_MAIN_R);
    // @include ff($F_MAIN_R);
    font-size: 13px;
}
.side-nav {
    padding: 15px 0 15px 15px;
}
.slideOutMenu-inner{
    & > a {
        line-height: 1.3em;
        text-align: center;
        width: 100%;
        display: block;
        @include ff($F_MAIN_B);

        position:relative;
        color:$black!important;
        padding: 6px 30px;


        padding-top: 0;
        &:active, &:focus{
            color:$black;
        }
        &:after{
            transform: rotate(-180deg);
            content: '';
            position:absolute;
            top: 3px;
            left: 4px;
            display: block;
            width: 9px;
            height: 11px;
            margin-bottom: 30px;
        }
    }

}
.mob-switch {

    position: absolute;
    right: 0;
    padding: 10px!important;
    top: 6px;
}

.header-container {
    position: relative;
    top: 0;
    width: 100%;
    z-index: 99;
    transform: translateY(0);
    
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($sm) {
            position: relative;
            margin-bottom:15px;
        }
        
    
}

.header-container {
    background-color: #fff;
}

@at-root {
    form#searchbox {
        padding: 0;
    }
}


#header {


    @include breakpoint($xs, $lg) {
        padding-left: 15px;
        padding-right: 15px;
    }


    @include breakpoint($sm) {
        height: 45px;
    }

    @include breakpoint($xs) {

        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    > div > .container {
        .rowas {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 25px;
        }


    }



}

#languages-block-top div.current span {
    font-size: 13px;
    margin-top: 5px;
}
#languages-block-top div.current {
    padding: 3px;
}
#languages-block-top div.current:after {

    position: relative;
    top: -2px;
}
#languages-block-top ul {

    top: 30px;
}
#languages-block-top {
    cursor: pointer;
}



@include breakpoint($sm) {
    #header {
        padding-top: 12px;
        margin-bottom: 0;
        padding-bottom: 8px;
        z-index: 10;
        position: static;
    }

  


    #mobile-header {
        .sf-menu {
            margin-bottom: 0;
            & > li {
                & > a {
                    @include ff($F_MAIN_B);
                    padding: 14px 0;
                    display: block;
                    padding-right: 30px;

                }
                &.closed > a {
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        display: block;
                        width: 9px;
                        height: 11px;
                    }
                }
                a {
                    font-size: 13px;
                }
            }
        }
        .slideOutMenu {
            display: none;
            overflow: scroll;
        }
        .slideOutMenu.show {
            padding: 15px;
            .slideOutMenu-inner > .menu-item-wrap > .menu-item-wrap > ul {
                display: block;
                li {
                    padding: 1.5rem 5rem 1.5rem 2rem;
                }
            }
            overflow: scroll;
            height: 100vh;
            position: fixed;
            top: 0;
            z-index: 1111;
            right: 0;
            left: 0;
            background: white;
            width: 100%;
            flex-wrap: wrap;
            width: 100%;
            left: 0px;
            animation-name: animateOpen;
            animation-duration: 0.5s;
        }
        .slideOutMenu.closing {
            .slideOutMenu-inner > .menu-item-wrap > .menu-item-wrap > ul {
                display: block

            }
            animation-name: animateClose;
            animation-duration: 0.5s;
        }

        @keyframes animateOpen {
            from {
                margin-left: -300px;
            }
            to {
                margin-left: 0;
            }
        }
        @keyframes animateClose {
            from {
                margin-left: 0px;
            }
            to {
                margin-left: -300px;
            }
        }

    }

    .side-nav {
        height: 200vh;
        li > a:hover {
            background-color: white;
        }
    }

    #slide-out-innerx {
        height: 100vh;
        overflow: scroll;
    }

    .prisijungimasNuoroda {
        margin-top: 30px;
    }
    .slideOutMenu-inner {
        & > .menu-item-wrap {
            margin-top: 20px;
        }
    }

    #mobile-header .slideOutMenu-inner {
        ul {
            li {
                a {
                    padding: 15px 30px 15px 0px;
                }
                ul {
                    padding-left: 20px;
                    a {
                        padding: 7px 30px 7px 0px;
                        .mob-switch {
                            top: -2px;
                        }
                    }
                }
            }
            li.submenuopen {
                & > .menu-item-wrap > .menu-item-inner-wrap > ul {
                    display: block;
                }
            }
            ul {
                display: none;
                ul {
                    a {
                        .mob-switch {
                            display: none;
                        }
                    }
                }

            }
        }
    }
}


#mobile-header {
    z-index: 1000;
    position: relative;


}
#mobile-menu {
    display: none;
}

#slide-out {
    left: -105%;
    position: absolute;
}

#top-right, #header_logo {
    @include breakpoint(xs) {
        flex-shrink: 1;
    }
}

.rowas {
    @include breakpoint(xs) {
        flex-wrap: nowrap;
    }
}
#header #my-login #signin-dropdown:lang(et)  .wrapper .button.paypal {
  display: none!important;
}


#header #top-right #my-login {
    margin-right: 35px;
    position: relative;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($md) {
        margin-right:19px;
    }

}

#mini-fb-top {
    display: none;
    position: relative;
    right: 15px;
}

#head-favorite {
    display: inline-block;
    position: relative;
    margin-right: 5px;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($xs) {
    display: none;
    }


}

.header-popup {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, .25);
    border-radius: 4px;
    padding: 11px 14px 14px 14px;
    background-color: #fff;
    position: absolute;
    width: 270px;
    z-index: 99;
    display: none;
    margin-top: 50px;
}


#header #top-right #my-login #signin-link {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    display: flex;
    justify-content: center;
    position: relative;

    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
        span {
            font-size: 0 !important;
        }
    }


}


#top-right .acount-icon {
    background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -37px -141px;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    margin-right: 8px;
}

#header #top-right #my-login #signin-link > span {

        //// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        //@include breakpoint($md) {
        //        font-size: 0px;
        //}
        //
    
}

#header #my-login #signin-link:after {
    content: ' ';
    background: url(/themes/default-bootstrap/dist/images/icons.svg) no-repeat -456px -21px;
    width: 6px;
    height: 4px;
    display: inline-block;
    position: absolute;
    right: -11px;
    top: 50%;
    transform: translateY(-50%);
    
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($md) {
            display:none;
        }
        
    
}

#head-favorite .favorite-icon-block {
    background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -62px -140px;
    width: 17px;
    height: 16px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
}

#head-favorite .quantity-bubbles {
    left: 11px;
    top: -7px;
}

#mini-fb-top {

    @include breakpoint($xs) {
        display:inline;
            width: 20px;
    height: 20px;
    }
    position: relative;
    right: 10px;
    
    .icon-fb-top { 
        background: url(/themes/default-bootstrap/dist/images/fb_icon.svg);
        width: 26px;
        height: 22px;
        float: right;
        background-size: 26px 24px;
        
    } 
    
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($xs) {
        right: 15px;
    }
    
}

.icon.hamburger.md {
    background: url(/themes/default-bootstrap/dist/images/icons.svg) no-repeat -639px -16px;
    width: 27px;
    height: 17px;
}