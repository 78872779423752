.breadcrumb {
    display: flex;
    
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($lg) {
            margin-left:-15px;
        }
        
    
    
    ol, > span, .fromsearch  {
        font-weight: normal;
        line-height: 24px;
        margin-bottom: 34px;
        min-height: 6px;
        border-radius: 3px;
        position: relative;
        z-index: 1;
        border: none;
        background: rgba(0, 0, 0, 0);
        font-size: 13px;
        color: rgb(120, 120, 120);
        padding: 0;
        margin-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        clear: both;
        flex-wrap: wrap;
        list-style: none;
        @include breakpoint($sm) {
            //display: none;
            font-size: 11px;
            margin-bottom: 0px;
            flex-wrap: nowrap;
            margin-left: 15px;
            text-overflow:inherit;
            line-height: 28px;
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            overflow-scrolling: touch;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }


    }

    li {
        display: inline;
    }

    a {
        margin-left: 0;
        padding: 0 4px 0 0;

        &.crumb {
            padding-left: 0;
            padding-right: 1px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    span, a {
        // @include ff($F_MAIN_R);
        @include ff($F_MAIN_R);
        font-size: 13px;
        color: $grey2;
        @include breakpoint($sm) {
            //display: none;
            font-size: 11px;
        }

    }

    .navigation-pipe {
        width: 4px;
        margin-right: 6px;
        display: inline-block;
        text-indent: 0;
    }
}

> span {
    margin-right: 6px;
}

@include breakpoint($sm) {
    margin-top: -15px;
    margin-bottom: 15px;
    margin-right: -15px;
    border-bottom: 1px #f5f5f5 solid;
    //display: none;
    //margin-left: 0;

}
}
