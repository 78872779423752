@media screen and (min-width: $sm + 1) {
    .container {
        width: 1660px;
        max-width: 100%;
        margin: 0 auto;
        .row {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    #center_column {
        flex: 0 0 100%;
        max-width: 100%;
    }
}


@include breakpoint(sm) {
    .container {
        max-width: 100%;
    }
}
