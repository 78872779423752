//colors
$color_abbey_approx: #495057;
$color_mischka_approx: #ced4da;
$color_malibu_approx: #80bdff;
$color_azure_radiance_25_approx: rgba(0, 123, 255, .25);
$color_pale_sky_approx: #6c757d;
$color_gallery_approx: #e9ecef;
$black_42: rgba(0, 0, 0, .42);

:root {
	input[type="text"] {
		font-size: 16px;
	}
	input[type="email"] {
		font-size: 16px;
	}
}
label {
	display: inline-block;
	color: $color_gallery_approx;
	margin-bottom: 8px;
	margin-bottom: .5rem;
	font-size: 12.8px;
	font-size: .8rem;
	color: $grey3;
	font-weight: 400;
	display: block;
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
}
input {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	overflow: visible;
	color: inherit;
	font: inherit;
	margin: 0;
	line-height: normal;
	background-image: none;
}
.form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	padding: .375rem .75rem;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
	color: $color_abbey_approx;
	background-color: $white;
	//Instead of the line below you could use @include background-clip($clip)
	background-clip: padding-box;
	border: 1px solid $color_mischka_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: .25rem;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	&:focus {
		color: $color_abbey_approx;
		background-color: $white;
		border-color: $color_malibu_approx;
		outline: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 .2rem $color_azure_radiance_25_approx;
	}
	&:-ms-input-placeholder {
		color: $color_pale_sky_approx;
		opacity: 1;
	}
	&:disabled {
		background-color: $color_gallery_approx;
		opacity: 1;
	}
}
.form-control::-ms-expand {
	background-color: transparent;
	border: 0;
}
.form-control::-webkit-input-placeholder {
	color: $color_pale_sky_approx;
	opacity: 1;
}
.form-control::-ms-input-placeholder {
	color: $color_pale_sky_approx;
	opacity: 1;
}
.form-control::placeholder {
	color: $color_pale_sky_approx;
	opacity: 1;
}
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
::-webkit-input-placeholder {
	color: $grey2;
}
:-ms-input-placeholder {
	color: $grey2;
}
::-ms-input-placeholder {
	color: $grey2;
}
::placeholder {
	color: $grey2;
}
.input-field {
	position: relative;
	margin-top: 16px;
	margin-top: 1rem;
	label {
		color: $grey2;
		position: absolute;
		top: -8px;
		left: 0;
		height: 100%;
		cursor: text;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: transform .2s ease-out;
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: 0 100%;
		text-align: left;
		text-align: initial;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(12px);
		pointer-events: none;
		font-size: 16px;
	}

	&.is-error {
		input, textarea {
			border-color: $red!important;
			+ label {
				color: $red !important;
			}
		}
	}
}



textarea ~ .input-helper-error, textarea ~ .input-helper {
	top: -20px;
}

.input-field {
	margin-bottom: 21px !important;
}

textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	resize: vertical;
	color: inherit;
	font: inherit;
	margin: 0;
	overflow: auto;
	width: 100%;
	height: 62px;
	background-color: transparent;
	background-image: none;
	max-width: 100%;
	min-height: 31px;
	&.materialize-textarea {
		min-height: 31px;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid $color_gallery_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		outline: none;
		height: 24px;
		width: 100%;
		font-size: 16px;
		margin: 0 0 20px 0;
		padding: 3px 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: none;
		//Instead of the line below you could use @include box-sizing($bs)
		// 		box-sizing: content-box;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 5000s ease-in-out 0s,box-shadow 0.3s,border 0.3s;
		overflow-y: hidden;
		padding: 5px 4px;
		resize: both;
		&:disabled {
			color: $blackReal;
			color: $black_42;
			border-bottom: 1px dotted $black_42;
			+ label {
				color: $blackReal;
				color: $black_42;
			}
		}
		&:focus:not([readonly]) {
			border-bottom: 1px solid $cyan;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 1px 0 0 $cyan;
			+ label {
				color: $cyan;
			}
		}
		+ label:after {
			display: block;
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			opacity: 0;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: .2s opacity ease-out, .2s color ease-out;
		}
	}
}

.input-helper, .input-helper-error {
	position: relative;
	top: -15px;
	font-size: 13px;
	color: $grey15;
    @include ff($F_MAIN_B);
	// @include ff($F_MAIN_R);
	line-height: 17px;
}

.input-helper-error {
	color: $red;
	display: none;
}

input[type=text]:not(.browser-default) {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $color_gallery_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0;
	outline: none;
	height: 24px;
	width: 100%;
	font-size: 16px;
	margin: 0 0 20px 0;
	padding: 3px 0;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: none;
	//Instead of the line below you could use @include box-sizing($bs)
	box-sizing: content-box;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 5000s ease-in-out 0s,box-shadow 0.3s,border 0.3s;
	&:disabled {
		color: $blackReal;
		color: $black_42;
		border-bottom: 1px dotted $black_42;
		+ label {
			color: $blackReal;
			color: $black_42;
		}
	}
	&:focus:not([readonly]) {
		border-bottom: 1px solid $cyan;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 1px 0 0 $cyan;
		+ label {
			color: $cyan;
		}
	}
	+ label:after {
		display: block;
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		opacity: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: .2s opacity ease-out, .2s color ease-out;
	}
}
input[type=email]:not(.browser-default) {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $color_gallery_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0;
	outline: none;
	height: 24px;
	width: 100%;
	font-size: 16px;
	margin: 0 0 20px 0;
	padding: 3px 0;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: none;
	//Instead of the line below you could use @include box-sizing($bs)
	box-sizing: content-box;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 5000s ease-in-out 0s,box-shadow 0.3s,border 0.3s;
	&:disabled {
		color: $blackReal;
		color: $black_42;
		border-bottom: 1px dotted $black_42;
		+ label {
			color: $blackReal;
			color: $black_42;
		}
	}
	&:focus:not([readonly]) {
		border-bottom: 1px solid $cyan;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 1px 0 0 $cyan;
		+ label {
			color: $cyan;
		}
	}
	+ label:after {
		display: block;
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		opacity: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: .2s opacity ease-out, .2s color ease-out;
	}
}

.input-field label:not(.label-icon).active {
	transform: translateY(-5px) scale(.8225);
	transform-origin: 0 0;
}


//colors
$color_azure_radiance_25_approx: rgba(0, 123, 255, .25);
$color_keppel_approx: #2ab7a9;

.w180 {
	width: 180px;
}

button {
	&:focus {
		outline: 1px dotted;
		background-color: $color_keppel_approx;
		outline: none;
		background-color: transparent;
	}
	span {
		background: transparent;
		border: none;
	}
	&:hover span {
		border: none;
	}
}
button::-moz-focus-inner {
	border-style: none;
	border: 0;
	padding: 0;
}

.btn {
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	//Instead of the line below you could use @include user-select($select)
	user-select: none;
	border: 1px solid transparent;
	padding: 6px 12px;
	padding: .375rem .75rem;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: .25rem;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: none!important;
	outline: none!important;
	color: $white;
	background: #f22467;
	height: auto;
	&:focus {
		text-decoration: none;
		outline: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 .2rem $color_azure_radiance_25_approx;
		background-color: $black;
		color: $white;
	}
	&:hover {
		text-decoration: none;
		background-color: $blackReal;
	}
	&:disabled {
		opacity: .65;
	}
	span {
		background: transparent;
		border: none;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
	&.btn-inverse:focus {
		background-color: $white;
		color: $black;
	}

	&.btn-inverse:hover {
		background-color: $black;
		color: $white;
	}
}

.btn-inverse {
	color: $black;
	background-color: $white;
	border: 2px solid $black;
	font-size: 13px;
	text-align: center;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 4px;
	text-transform: none;
	text-transform: initial;
	line-height: 13px;
	display: inline-block;
	height: 41px;
	border-width: 1px;
        
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($xs) {
            height: 35px;
            width:50%;
        }
        
        

}

.last-text {
	display: none;
}


[type="checkbox"] {

	&:checked, &:not(:checked) {
		display: none;
	}

	+ label {
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		display: inline-block;
		height: 25px;
		user-select: none;
		&:before, &:after {
			content: '';
			position: absolute;
			border-radius: 2px;
		}
		&:before {
			width: 0px;
			height: 0px;
			top: 18px;
			left: 18px;
			z-index: 1;

			border: 2px solid transparent;

			transform: rotateZ(37deg);
			transform-origin: 100% 100%;

			transition: border .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
		}
		&:after {
			height: 18px;
			width: 18px;
			top: 0;
			left: 0;

			border: 1px solid $blackReal;
			background-color: transparent;

			transition: background-color .25s;
		}
	}
	&:checked + label, &.checked + label {
		&:before {
			width: 8px;
			height: 13px;
			left: 0;
			top: 1px;

			border-top: 2px solid transparent;
			border-left: 2px solid transparent;
			border-right: 2px solid #fff;
			border-bottom: 2px solid #fff;
		}
		&:after {
			background-color: $blackReal;
		}
	}

	&.grey + label:after {
		background-color: $grey2;
	}


	&:disabled + label {
		&:before {

		}
		&:after {

		}
	}
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance:textfield;
}



#comments-module {
  
    input, textarea {
    -webkit-appearance: none!important;
    -webkit-box-sizing: border-box!important;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 50px!important;
    border: 1px solid #d7d7d7!important;
    border-radius: 4px!important;
    padding: 18px 14px 18px 12px!important;
    font-size: 16px!important;
    margin-bottom: 0;
    font-family: brandon-bold,brandon_textbold;
    color: #000!important; 
    background: #fff !important;
}

  
    .select2-container--default .select2-selection--single {
        border: 1px solid #fff;
    }
    
    .select2-container--open {
        .select2-selection--single {
        border: 1px solid #d7d7d7;
    }
    }
    
    .select2-container {
            position: relative;
            top: 1px;
    }
    
    
   
    
    
    
    
    
  
    .image-upload .upload-clicker {
        @include ff($F_MAIN_R);
        color:#000;
    }

    .image-upload .thumb {
        margin-bottom: 0px;
    }
    .image-upload {
            margin-bottom: 33px;
    }
    
    button.btn-cancelq, button.btn-cancel, .answerskip {
            border: 1px solid #d7d7d7;
    border-radius: 4px;
    margin-left: 10px;
                height: 41px;
            @include ff($F_MAIN_R);
            width: 180px;
            max-width: 180px;
            color: #000;
            
                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint($xs) {
                    height: 35px;
                    width:48%;
                    max-width: 48%;
                      margin-left: 0px;
                      margin-left: 0px;
                }
                
            
    }
    
    button.btn-cancelq:after, button.btn-cancel:after, .answerskip:after {
        background:none;
    }
    
    
textarea {
    height: 260px !important;
    padding-top: 13px !important;
        }

    .is-error {
        input {
            border: 1px solid #f44336 !important;
                    }
                    textarea {
                        border: 1px solid #f44336 !important;
                                }
    }

 ::placeholder {
   @include ff($F_MAIN_R);
   color: #787878 !important;
}

    .input-helper, .input-helper-error {
            top: 6px;
    }

    .input-field {
            margin-bottom: 5px !important;
            margin-bottom: 5px !important;
    }
    
    #question-form .input-field:nth-child(2) {
        margin-top: 20px;
    }
    
    #question-form .input-field:nth-child(3) {
        margin-top: 25.4px;
    }
    
    input[type=text]:not(.browser-default):focus:not([readonly]) {
        box-shadow: none;
    }
    input[type=email]:not(.browser-default):focus:not([readonly]) {
        box-shadow: none;
    }
    
    textarea.materialize-textarea:focus:not([readonly]) {
         box-shadow: none;
    }

}

.questions-wrapper {
        #review-pagination {
                margin-top: 25px;
        }
}