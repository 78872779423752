body #header #main-menu > ul.sf-menu.menu-content .nav-level-0.holidays-menu > .sub-title {
        padding-left: 16px;
        position: relative;
        &:before{
            position: absolute;
            content: '';
            background: url("/themes/default-bootstrap/assets/images/kaledos.svg");
            width: 16px;
            height: 12px;
            left: 0;
            top: 5px;
            background-size: contain;
            background-repeat: no-repeat;;

        }

}
#mobile-header #mobile-menu #slide-out .menu-content .nav-level-0.holidays-menu > .sub-title {
    padding-left: 18px;
    position: relative;
    &:before{
        position: absolute;
        content: '';
        background: url("/themes/default-bootstrap/assets/images/kaledos.svg");
        width: 16px;
        height: 12px;
        left: 0;
        top: 20px;
        background-size: contain;
        background-repeat: no-repeat;;

    }

}
#targetThird.kaledos.two{
    #kaledos-kalendorius {
        display: flex;
    }
    padding-bottom: 0px;
    .popup-info{
        margin-top: 0;
        padding: 0;
    }
    .popup-box{
        padding: 0!important;
    }
    .popup-info .content {
        margin-bottom: 0;
    }
    .close-popup {
        overflow: hidden;
        i{
            display: none;
        }
        &:after{

            @include ff($F_MAIN_R);
            content: '+';
            position: absolute;
            font-size: 80px;
            color: white;
            left: 0;
            top: 0;
            width: 50px;
            height: 50px;
            line-height: 50px;

            transform: rotate(-45deg);
            font-weight: 400;

        }
        opacity: 0.5;
        background-color: rgb(12, 13, 17);
        top: 10px;
        right: 12px;

        width: 50px;
        height: 50px;

        &:hover{
            opacity: 0.9;
        }
        &:active{
            top: 10px!important;
        }

    }
}






#kaledos-kalendorius {
    a{
        display: flex;
        align-items: baseline;
    }
    img{
        max-width: 100%;
    }
    position: relative;
    .timer-op {
        min-width: 34px;
    }
    #tte-dd{

    }
    #timer-to-end-inner {
        display: flex;
        position: absolute;
        bottom: 193px;
        left: auto;
        font-size: 24px;
        color: #bba78e;
        letter-spacing: -1.2px;
        right: 298px;
        @include ff($F_MAIN_B);
    }
}

#targetThird.kaledos.two .popup-holder .popup-box .popup-info {
    margin: 18px 0 0;
    padding: 0 16px;
    margin: 0;
    padding: 0;
}

@include breakpoint($sm){
    .mfp-bg {

        opacity: 0.8 !important;
    }
    .timer-op {
        min-width: 20px;
    }
    #kaledos-kalendorius .timer-op{
        min-width: 18px;
    }
    .mfp-content{
        max-width: 500px!important;
    }
    #targetThird.kaledos.two {
        padding-bottom: 0px;
        height: auto!important;
    }
    #targetThird.kaledos.two .close-popup {

        top: 10px;
        right: 5px;
        width: 30px;
        height: 30px;
        top: 4px !important;
        &:after{

            font-weight: 400;
            width: 19px;
            height: 15px;
            transform-origin: center center;
            font-size: 30px;
            line-height: 30px;
        }
    }
    #targetThird.kaledos.two .close-popup:active {
        top: 4px !important;
    }

    #kaledos-kalendorius #timer-to-end-inner {


        bottom: 155px;
        left: auto;
        font-size: 21px;
        letter-spacing: -1.2px;
        right: 242px;

    }
    #targetThird.kaledos.two {

        max-width: 700px;
    }

}
@include breakpoint($xs){
    body .mfp-bg {

        opacity: 0.8 !important;
    }
    #targetThird.kaledos.two {
        max-width: 345px;
    }
    #kaledos-kalendorius #timer-to-end-inner {

        bottom: 72px;
        left: auto;
        font-size: 14px;
        letter-spacing: -1.2px;
        right: 104px;

    }
}
@media screen and (max-width: 374px){
    #targetThird.kaledos.two {
        max-width: 290px;
    }
    #kaledos-kalendorius #timer-to-end-inner {

        bottom: 60px;
        left: auto;
        font-size: 12px;
        letter-spacing: -1.2px;
        right: 90px;

    }
}
