
#search_block_left {
    margin-bottom: 0;

    button {
    right: 3px;
    left: auto;
    top: 3px;

        > span {
            display: none;
        }

 
    }

    .block_content {
        position: relative;
        width: 100%;
        margin: 0;
        text-align: left;
        clear: both;
    }

    form {
        display: flex;
    }

    .mobile-close-search {
        display: none;
    }

    .form-control {
        flex: 0 1 100%;
        font-size: 13px;
        @include ff($F_MAIN_R);
        color: $black;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
        height: 30px;
        margin: 0 0 20px;

        &::placeholder {
            color: $greyMediumText;
        }

        &.ui-autocomplete-loading {
            background-image: none;
        }
    }
}

#searchbox {
    display: flex;
    padding: 0;
    width: 100%;
    position: relative;
}

#search_query_block {
    transition: background-color 5000s ease-in-out 0s, box-shadow 0.3s, border 0.3s;
    padding: 3px 0;
    flex: 0 1 100%;
    font-size: 13px;
    @include ff($F_MAIN_R);
    color: $black;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid $black;
    border-radius: 0;
    height: 30px;
    margin: 0 0 20px;
    padding-right: 34px;
    display: block;
    line-height: 1.5;
    width: 100%;
    box-sizing: border-box;
    &:focus {
        border-bottom: 1px solid #5688d2;
    }
}

.mobile-search {
    display: none;
}

body {
    &.mobile-menu-open,
    &.mobile-search-open {
        #page {
            overflow: hidden;
            position: fixed;
            height: 100%;
            width: 100%;
        }
    }
}


@include breakpoint($sm) {

    #search_block_left {
        margin-bottom: 0;
        margin-top: 0px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.9);
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
        min-width: 100%;


        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        height: 120vh;

        button {
  
            height: 24px;
        }

        .mobile-close-search {
            display: block;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            &:after {
                content: " ";
                display: block;
                height: 49px;
                width: 49px;
                left: -16px;
                top: -16px;
                z-index: 9999999;
                position: absolute;
            }
        }

        .form-control {
            min-height: 54px;

            padding-left: 30px;
            flex: 0 0 100%;
            font-size: 16px;
            @include ff($F_MAIN_R);
            color: $black;
            letter-spacing: -0.01em;
            margin: 0;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-bottom: 2px solid $grey1 !important;
        }
    }

    #search_query_block {
        position: relative;
        padding-right: 6px;
        padding-left: 27px;
    }

    .mobile-search {
        flex: 1 0 50%;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        margin-right: 15px;
        cursor: pointer;

        span {
            font-size: 13px;
            color: $black;
            @include ff($F_MAIN_R);
            opacity: 0.4;
            letter-spacing: -0.01em;
            margin-top: 5px;
            margin-right: 11px;
            font-size: 14px;
        }

    }

    .custom-search-form {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0px;
        height: 120vh;
        width: 100%;
        z-index: 99999;
        display: none;
        &.active {
            display: block;
        }
    }


    .search_query {
        border-bottom: none !important;
        box-shadow: none !important;
    }

    #search_query_block {
        margin-bottom: 0;
        border: 0;
        min-height: 54px;
        font-size: 18px;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 0;
        flex: 1 1 100vw;
        background: white;
    }
}
// ----------------------------------------------------------- XS ---------------------------------------------------
@include breakpoint($xs) {
    .mobile-search {
        span {
            font-size: 13px;
            margin-right: 1px;
            margin-top: 1px;
        }
      
        
    }
}


@include breakpoint($xs+1, $sm) {
    .mobile-search {
        span {
            margin-right: 11px;
        }

        &:after {
            flex: 0 0 18px;
            position: relative;
            left: -9px;
            top: 1px;
        }
    }
}


#search-block .button-search .material-icons-search {
    background: url(/modules/klipaccount/views/img/paskyros_ikonos.svg) no-repeat -116px -142px;
    width: 13px;
    height: 14px;
    display: inline-block;
}

#search_block_left button {
    position: absolute;
}

.find-text {
    @include ff($F_MAIN_R);
    color:#8b8b8b;
}
