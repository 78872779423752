#main-menu {
  padding: 0;
  margin-top: 11px;
  display: flex;
  align-items: center;
  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($md) {
    margin-top: 10px;
  }
  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    display: none;
  }

  a {
    color: $blackAlt;
    @include ff($F_MAIN_B);
    font-size: 15px;
    font-weight: 400;
    text-shadow: none;

    &:hover, &:focus, &:active {
      background: white;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  li {
    position: static;
    border: none;
    float: none;
    display: inline-block;

    ul {
      top: 126px;
      position: static;
    }

    > div > div {
      z-index: 8;
    }
  }

  .second-level {
    width: 100%;
    background: white;
    display: none;
  }

  .menu-item-wrap {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 3;
    height: 0;
    overflow: hidden;
    background: white;

    top: 86px;


    .menu-item-inner-wrap {
      max-width: 1630px;
      margin: 0 auto;
      padding: 15px;
      margin-top: 0px;
    }
  }

  .active-bar {
    position: absolute;
    border-bottom: 2px solid $black;
    top: 55px;
    transform: translateY(55px);
    transition: left 0.2s, opacity 0.4s, width 0.2s;

    @include breakpoint($md) {
      top: initial;
      bottom: 10px;
      transform: none;
    }
  }


  @include breakpoint($md) {
    padding: 0;
  }

  > ul.sf-menu {
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;

    margin: 0 63px;

    @include breakpoint($lg) {
      margin: 0;
    }

    @include breakpoint($md) {
      justify-content: space-between;
      height: 30px;
      margin: 0 0px;
    }


    &.menu-content {
      flex-wrap: nowrap;


      .nav-level-0 {
        flex: 0 1 auto;

        padding-bottom: 8px;


        > .sub-title {
          color: #000;
          font-size: 13px;
          padding: 0;
          margin: 0;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 700;
          line-height: 24px;



        }

        > .menu-item-wrap {
          position: absolute;
          height: auto;
          display: none;
          padding-left: 15px;
          padding-right: 15px;
          top: 122px;
          animation-delay: 0s;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);

          > .menu-item-inner-wrap {
            padding: 0;

            > ul {
              column-count: 5;
              -moz-column-count: 5;
              -webkit-column-count: 5;

              margin-bottom: 30px;
              margin-top: 30px;

              // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
              @include breakpoint($lg) {
                margin-top: 0px;
              }

              // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
              @include breakpoint($md) {
                column-count: 3;
              }


            }

            .sub-menu-ul-left {
              float: left;
              width: calc(100% - 300px);
            }

            .sub-menu-banner-right {
              float: right;
              padding: 0;
              margin-top: 34px;
              margin-bottom: 60px;

              img {
                border-radius: 4px;
              }

              // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
              @include breakpoint($lg) {
                margin-top: 7px;
              }
              // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
              @include breakpoint($md) {
                margin-bottom: 30px;
              }


            }

            .sub-menu-ul-left-content-14 {
              .nav-level-1-woman, .nav-level-1-children {
                display: none;
              }
            }

            .parent-not-have-children {
              margin-bottom: 10px !important;
            }

            .has-children-358 {
              display: none;
            }

            .sub-menu-show-all-brand {
              float: left;
              padding: 0;
              padding-top: 0px;
              border: 1px solid;
              width: 456px;
              height: 50px;
              border-radius: 4px;
              border: solid 2px #d7d7d7;
              letter-spacing: -0.8px;
              font-size: 16px;
              padding-top: 11px;
              text-align: center;
              margin-top: -10px;
              margin-bottom: 40px;

              // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
              @include breakpoint($md) {
                margin-top: -30px;
              }


              &:hover {
                border: solid 2px #c6c6c6;
              }

            }


            @include breakpoint($sm, $lg) {
              margin-top: 20px;
            }

            .sub-heading {
              display: none;
            }
          }


          @include breakpoint($md) {
            top: 140px;
          }

          > .menu-item-wrap {
            position: absolute;
            height: auto;
            display: none;
            padding-left: 15px;
            padding-right: 15px;
            top: 170px;
            animation-delay: 0s;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);

            @include breakpoint($md) {
              top: 140px;
            }

            > .menu-item-inner-wrap {
              padding: 0;

              > ul {
                margin-bottom: 30px;
                margin-top: 30px;

                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint($md) {
                  margin-top: 0px;
                }


              }

              .sub-menu-ul-left {
                float: left;
                width: calc(100% - 300px);
              }

              .sub-menu-banner-right {
                float: right;
                padding: 0;
                margin-top: 34px;
                margin-bottom: 25px;

                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint($md) {
                  margin-top: 0px;
                }


              }

              .sub-menu-ul-left-content-14 {
                .nav-level-1-woman, .nav-level-1-children {
                  display: none;
                }
              }

              .parent-not-have-children {
                margin-bottom: 10px !important;
              }

              .has-children-358 {
                display: none;
              }

              .sub-menu-show-all-brand {
                float: left;
                padding: 0;
                padding-top: 0px;
                border: 1px solid;
                width: 456px;
                height: 50px;
                border-radius: 4px;
                border: solid 2px #d7d7d7;
                letter-spacing: -0.8px;
                font-size: 16px;
                padding-top: 11px;
                text-align: center;
                margin-top: -10px;
                margin-bottom: 40px;

                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint($md) {
                  margin-top: -30px;
                }


                &:hover {
                  border: solid 2px #c6c6c6;
                }

              }


              @include breakpoint($sm, $lg) {
                margin-top: 20px;
                padding-left: 15px;
              }

              .sub-heading {
                display: none;
              }
            }
          }
        }
      }

      .menu-item-inner-wrap-brands {
        .nav-level-1-custom {
          .sub-title {
            max-width: 180px;
          }

        }
      }

      .nav-level-1 {
        width: 100% !important;
        height: auto !important;
        padding-right: 30px !important;
        margin-bottom: 30px;
        -webkit-column-break-inside: avoid; /* Chrome, Safari */
        page-break-inside: avoid; /* Theoretically FF 20+ */
        break-inside: avoid-column; /* IE 11 */
        display: table; /* Actually FF 20+ */

        /* Safari fix */
        @media not all and (min-resolution: .001dpcm) {
          @supports (-webkit-appearance:none) {
            display: inline-block;
          }
        }
        @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
          display: inline-block;
        }

        @include breakpoint($md) {
          margin-right: 65px;
        }

        &.parent--link {
          display: none;
        }

        a {
          text-align: left;
          padding-left: 0;
        }

        > a {
          font-size: 13px;
          @include ff($F_MAIN_B);
          color: $black;
          padding-bottom: 5.5px;
          padding-top: 5.5px;
          border-bottom: 1px solid transparent;

          &.sub-title {
            line-height: 1.25;
            letter-spacing: 0.2px;
            margin-bottom: 4px;
            font-size: 13px;
            font-weight: 700;
          }
        }


        .menu-item-wrap {
          position: relative;
          top: 0;
          left: 0;
          height: auto;

          ul {
            display: flex;
            flex-direction: column;
          }

          > .menu-item-inner-wrap {
            padding: 0;
          }
        }

        &.first_block {
          flex: 0 0 100%;
          min-height: 150px;

          @include breakpoint($lg) {
            display: none !important;
          }

          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              flex: 0 0 100%;

              a {
                font-size: 13px;
                @include ff($F_MAIN_B);
                text-align: left;
              }
            }
          }
        }
      }

      .nav-level-2 {
        > a {
          font-size: 13px;
          @include ff($F_MAIN_R);
          color: #262626;
          // min-height: 24px;
          padding-bottom: 9px;
          padding-top: 9px;
          line-height: 13px;
          display: flex;
          align-items: center;
        }

        &.has-children {
          > .menu-item-wrap {
            order: 3;
            height: 0;
            display: none;
            flex: 0 0 100%;
          }

          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          cursor: pointer;

          .sub-title {
            order: 1;
            flex: 0 0 80%;
            padding-right: 0;
            margin-right: 0;
          }

          &:after {
            order: 2;
            flex: 0 0 6px;
            order: 2;
            content: ' ';
            margin-left: auto;
            transition: 0.2s transform;
            margin-top: 2px;
          }

          &:before {
            content: " ";
            position: absolute;
            width: 50%;
            height: 100%;
            top: 0;
            right: 0;
          }

          &.open {
            &:after {
              transform: rotate(180deg);
            }
          }
        }


      }

      .nav-level-3 {
        > a {
          font-size: 13px;
          @include ff($F_MAIN_R);
          color: $greyMediumText;
          text-indent: -5px;
          padding-left: 5px;

          &:before {
            content: '-';
          }

        }
      }
    }
  }

  @include breakpoint(md) {
    display: flex;
    order: 3;
    width: 100%;
    & > .sf-menu.menu-content {
      position: static;
      display: inline-block;
      width: auto;
      display: flex;
      width: 100%;
      justify-content: space-between;
      display: flex;
      position: static;
      flex: 1 0;
      flex-wrap: wrap;

      & > li {
        position: static;
        border: none;
        float: none;
        display: inline-block;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        display: flex;
        width: auto;
        justify-content: space-between;
        border-bottom: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        width: auto;
        text-align: right;
        display: block;
      }
    }

    .menu-item-wrap {
      top: 117px;
    }
  }

  @include breakpoint(xs) {
    display: none;
  }
}

.papildomas-kuponas a {
  margin-top: 20px;
  color: rgb(86, 136, 210) !important;
  line-height: 14px;
  font-size: 14px;
  display: inline-block;
}

.mobile-extra-menu {
  display: none;
}

.sf-menu {
  position: static;
  background: white;
  text-align: left;
  background: white;
}


@media screen and (max-width: $md) {


  .sf-menu {

    & > li {

      & > a {

        padding: 0;
        text-align: right;
      }
    }
  }

  .rowas {
    display: flex;
    flex-wrap: wrap;

  }


}

@media screen and (max-width: $sm) {
  .minusas {
    right: 18px;
  }
  .pliusas {
    right: 18px;
  }

  #mobile-menu {
    display: block
  }

  #mobile-menu .sf-menu > li {
    li {
      display: block;;
    }

    ul {
      // display: none;
    }
  }


  .menu-item-inner-wrap a {
    position: relative;
  }

  #main-menu > .sf-menu.menu-content {
    display: none;
  }

  @include breakpoint(xs) {
    display: none;
  }
}

.meniu-border {
  background: #f5f5f5;
  width: 100%;
  height: 1px;
  margin-top: 20px;

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($lg) {
    width: 103%;
    margin-left: -15px;
    margin-right: -15px !important;
  }


  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    display: none;
  }

}


.meniuAtsidarymasMobilus {
  display: none;
  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($sm) {
    left: 15px;
    display: block;
    max-height: 20.02px;
  }

  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($xs) {
    margin-top: -2px;
  }


}


@include breakpoint('1590px') {
  .love-yourserf-a {
    display: none;
  }
}

.nav-level-3 {
  .sub-title {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
@include breakpoint($sm) {
  #search-block {
    display: none !important;
  }
}
