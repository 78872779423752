@import '../../data';

$color_azure_radiance_approx: #007bff;
$color_endeavour_approx: #0056b3;
$color_cerulean_approx: #039be5;
$color_keppel_approx: #2ab7a9;
$color_boulder_approx: #787878;
$black: #000;
$color_havelock_blue_approx: #5688d2;
$color_cadet_blue_approx: #aab2bd;
$color_iron_approx: #d7d7d7;

.product-footer {
    .glider-track {
        div.color-list-container {
            top: 5px;
        }
        .rb-pro-att {
            margin-top: 10px;
        }
        
        .icon.plus.md {
            top: -2px;
            
              
                        @include breakpoint($sm) {
                            top: 0px;
                        }
                        
            
        }
        
        .review-score-count {
            margin-top: 1px;
        }
        
        .review-score-count {
            margin-top: 2px;
        }
        
         @include breakpoint($xs) {
            .content_price {
                    margin-top: 2px;
            }
            
            .product-brand-line {
                    font-size: 12px;
            }
            
            
        }
        
        .right-block h5 {
            display: block !important; 
        }
 
    }
}

div.color-list-container {
	margin: 0;
	max-height: initial;
	padding: 0;
	position: relative;
        top: 2px;
        
        
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($xs) {
            top: 4px;
        }
        
        
        
	.color_to_pick_list {
		display: flex;
		width: 100%;
        max-width: 100%;
		overflow: hidden;
        min-height: 45px;
        .owl-carousel .owl-stage-outer {
            display: flex;
            align-items: center;
        }
		@include breakpoint(xs) {
			max-width: 85%;
		}
	}

	&:not(.initialized) {
		display: flex;
		justify-content: center;

		.color_to_pick_list > span {
			flex-basis: 24px;
			flex-grow: 0;
			flex-shrink: 0;

			margin-right: calc(#{100% / 7} - 24px);
			@include breakpoint(md) {
				margin-right: calc(#{100% / 5} - 24px);
			}

			@include breakpoint(560px) {
				margin-right: calc(#{100% / 4} - 24px);
			}

			@include breakpoint(320px) {
				margin-right: calc(#{100% / 3} - 24px);
			}
		}

	}




	span {
		margin-left: 1px;
margin-right: 1px;
display: inline-block;
width: 19px;
height: 19px;

@include breakpoint('1015px') {
    width: 16px;
    height: 16px;
    }

		box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, .2);
                
                &:hover {
                    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, .5);
		}
                
                
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50px;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: 0.3s;
		a {
			width: 15px;
			height: 15px;
                    
                // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
                @include breakpoint('1015px') {
                    width: 12px;
                    height: 12px;
                }
                
                    
                    
			margin-top: 2px;
			margin-left: 2px;
			opacity: 1!important;
			
			display: inline-block;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50px;
		}
		
	}


	&.morethan7 {
		padding-right: 18px;
		padding-left: 18px;
		.owl-nav {
			display: block;
		}

		@include breakpoint(sm) {
			padding-left: 0;
			padding-right: 0;
			.owl-nav {
				display: none;
			}
		}
	}

	.owl-nav {
		display: none;
		> button.owl-prev, > button.owl-next {
			display: block;
			position: absolute;
			background: url("/themes/default-bootstrap/dist/images/icons.svg") no-repeat -242px -11px;
			width: 9px;
			height: 16px;
			top: calc(50% - 8px);
			padding: 0;
			margin: 0;
			cursor: pointer;
			outline: none;
			border: none;

            &.disabled {
                opacity: 0.4;
            }

			&:hover {

			}
		}

		> button.owl-next {
			right: 0;
			transform: scale(1);
		}

		> button.owl-prev {
			left: 0;
			transform: scale(-1);
		}

		span {
			display: none;
		}
	}

	.owl-stage {
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
	}

	.owl-dots {
		display: none;//
	}

    .owl-item {
        flex-shrink: 0;
        flex-grow: 0;
    }
}


//colors
