@import '../data';

@import '../tools/icons';
@import '../tools/bootstrap/bootstrap-reboot';
// @import './common/grid'; //

@import '../common/default';
@import '../common/productSmall';
@import './components';

// body:not(.fonts-loaded) * {
//     letter-spacing: 0.22px !important;
// }
.full-display #columns #center_column {
  max-width: 100%!important;
  margin-top: 0px!important;
}


#columns {
  > .row {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: nowrap;
  }

  #center_column {
    flex-shrink: 1;
    max-width: 1366px;
    margin-top: -47px;

    @include breakpoint(sm) {
      max-width: 100%;
      margin-top: 0;
      flex-basis: 100%;
    }
  }

  #left_column {
    flex-grow: 1;
  }
}


//colors
$color_shark_approx: #262626;
$color_boulder_approx: #787878;

//fonts
$font_0: "Roboto", sans-serif;
$font_1: "Roboto", sans-serif;
$font_2: "Roboto", sans-serif;
$font_3: "Roboto", sans-serif;
$font_4: "Roboto", sans-serif;
$font_5: "Roboto", sans-serif;
$font_6: 'sans-serif';
$font_7: "Roboto", sans-serif;
$font_8: 'sans-serif';

h1 {
  text-transform: inherit;
  margin-top: 0;
  margin-bottom: .5rem;
  font-family: inherit;

  line-height: 1.2;
  color: inherit;
  font-size: 2em;
  margin: .67em 0;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;

  font-family: "Roboto", sans-serif;
  font-size: 28px !important;
  font-weight: 700 !important;
  letter-spacing: -0.5px;
  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint('1015px') {
    font-size: 22px !important;
  }


  // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
  @include breakpoint($xs) {
    font-size: 20px !important;
  }


  vertical-align: baseline;

  &.page-heading {
    color: $black;
    // @include ff($F_SECOND_L);
    @include ff($F_SECOND_L);
    text-transform: inherit;
  }
}

.page-heading {
  text-transform: uppercase;
  padding: 0 0 17px;
  margin-bottom: 30px;
  overflow: hidden;
  margin-top: 18px;
  padding-bottom: 19px;

  &.product-listing {
    margin-top: 18px;
    text-align: center;
    padding-bottom: 12px;
    margin-bottom: 0;
    font-size: 26px;
  }
}

#stickas .disableSelect {
  opacity: 0.5;
}

@media (max-width: 1024px) {
  .page-heading.product-listing {
    margin-top: 63px;
  }
}

@media screen and(max-width: 991px) {
  .page-heading.product-listing {
    margin-top: 12px;
  }
  h1.page-heading.product-listing {
    font-size: 24px;
  }
}

@media only screen and(max-width: 767px) {
  .page-heading.product-listing {
    margin-top: 0px !important;
  }
}


@media screen {
  h1.page-heading {
    text-align: center;
  }
}

// $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
@include breakpoint($sm) {
  .sortPagiBar {
    display: flex !important;
    justify-content: center;
    margin-top: 5px;
  }

  #productsSortForm {
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }

  .select2-selection--single {
    text-align: center;
  }

  .mobile-filter-button {
    width: 49%;
    margin-left: 1%;
  }

  #productsSortForm {
    width: 49% !important;
    margin-right: 1% !important;
  }

  .sortPagiBar .select2 {
    min-width: 100% !important;
    width: 100% !important;
  }

  .product-count {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .content_sortPagiBar .heading-counter {
    float: none;
  }

}
.not-found-product404 {
  width:100%;
  min-height:500px;
  text-align:center;
  font-size:14px;
  color:#000000;
  line-height: 1;
  margin-top: -20px;
  @include breakpoint($sm) {
    margin-top: 0px;
  }

  h1 {
    font-size: 30px!important;
    font-weight: bold!important;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.5px;
    margin-top: 0px;
    margin-bottom: 52px;
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
      font-size: 24px !important;
    }



  }
  .text-big {
    font-size: 42px!important;
    @include ff($F_MAIN_B);
    font-weight: bold!important;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -2.1px;
    margin-bottom: 20px;
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($sm) {
      font-size: 30px !important;
      margin-bottom: 15px;
    }

  }

  .text-note {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1px;
    color: #787878;
    margin-bottom: 34px;
    @include breakpoint($sm) {
      font-size: 16px !important;
      margin-bottom: 15px;
    }

    strong {
      color: #787878!important;
    }
  }
  .text-help {
    font-size: 18px!important;
    font-weight: bold!important;
    @include ff($F_MAIN_B);
    letter-spacing: -0.9px;
    color: #000;
    margin-bottom: 18px;
    @include breakpoint($sm) {
      font-size: 15px !important;
      margin-bottom: 11px;

    }

  }
  h3 {
    font-size: 30px;
    font-weight: bold !important;
    letter-spacing: -1.5px;
    margin-bottom: 25px;
    @include breakpoint($sm) {
      font-size: 24px !important;
    }
  }
  .buttons {
    margin-bottom: 115px;
    @include breakpoint($sm) {
      margin-bottom: 55px;
    }

  }
  .btn-404 {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #d7d7d7;
    font-size: 16px !important;
    letter-spacing: -0.8px;
    text-align: center;
    border-radius: 4px;
    text-transform: none;
    text-transform: initial;
    line-height: 16px;
    display: inline-block;
    padding: 16px 43px;
    @include breakpoint($sm) {
      font-size: 13px !important;
      padding: 12px 42px;
    }

    &:hover {
      opacity:0.8;
    }
  }
  .cms-full {
    max-width: 1140px;
    margin: 0 auto;
  }
  .cat-row {
    margin-bottom: 102px;
    @include breakpoint($sm) {
      margin-bottom: 30px;
    }
  }
  .rte .category {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    line-height: normal;
    @include breakpoint($xs) {
      font-weight: bold;
      font-size: 10px !important;
      line-height: 1.6;
      letter-spacing: 1px;
      margin-top: 11px;
    }
    img {
      width: 100%;
      max-width: 100%;
      min-width: 120px;
    }
    .url {
      text-decoration: none;
      transition: all 0.3s;
      cursor: pointer;
      display: inline-block;
      @include breakpoint($xs) {
        display: block;
        transition: none;
      }

    }

    .url:hover {
      transform: translateY(-15px);
      @include breakpoint($xs) {
        transform: none;
      }
    }

  }
  .product-block {
    position: relative;
    margin-left: 40px;
    margin-right: 40px;
    // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
    @include breakpoint($xs) {
      margin-left: 0px;
      margin-right: 0px;
    }

    .dotsRecommended .glider-prev {
      top: 50%;
      left: -40px!important;
    }

    .dotsRecommended .glider-next {
      top: 50%;
      right: -40px!important;
    }
    .product_listas {
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      box-sizing: border-box;

      li {
        flex: 0 1 24.95%;
        max-width: 100%;
      }
    }

  }
}
