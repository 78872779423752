@import '../../data';

.product_list {
    display: flex;
    flex-wrap: wrap;
    width: auto !important;
    &.loading {
        li {
            opacity: 0.4;
        }
        &:after {
            content: '';
            position: fixed;
            background-image: url('/themes/default-bootstrap/dist/images/369.gif');
            height: 48px;
            width: 48px;
            top: calc(50vh - 24px);
            left: calc(50vw - 24px);
            z-index: 101;
        }
    }

    @include breakpoint(xs) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

//colors
$color_azure_radiance_approx: #007bff;
$color_endeavour_approx: #0056b3;
$color_cerulean_approx: #039be5;
$color_boulder_approx: #787878;
$color_havelock_blue_approx: #5688d2;
$color_gallery_approx: #ededed;

#top-filters {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;

    h3 {
        font-size: 20px;
        margin-top: 8px;
        display: block;
        margin-bottom: 20px;
        letter-spacing: -1px;
font-weight: 700;
        // $xs: 767px; $sm: 991px; $md: 1365px; $lg: 1660px
        @include breakpoint($md) {
            font-size: 16px;
        }


    }


    li {
        border-radius: 4px;
        overflow: hidden;
        display: inline-block;
        margin-right: 6px;
        margin-bottom: 6px;
        color: $grey2;
        background: transparent;
        a {
            font-size: 13px;
            display: inline-block;
            padding-top: 10px;
            color: $grey2;
            text-decoration: none;
            position: relative;
            padding-bottom: 10px;
            padding-left: 15px;
            padding-right: 30px;
            background: $grey5;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                right: 11px;
                top: 15px;
                background-image: url('/themes/default-bootstrap/dist/images/cancelx.png');
                background-repeat: no-repeat;
                width: 9px;
                height: 9px;
            }
            &.clearAll {
                background: transparent;
            }

            &:hover {
                color: #0f0f07;
            }

        }
    }
    ul {
        margin-bottom: -6px;
        display: flex;
        flex-wrap: wrap;
    }
    &.active {
        margin-top: 20px;
    }
}

@media screen and(max-width: 991px) {
    #top-filters {
        display: none;
    }
    body #top-filters {
        display: block;
    }
}

i[class^="icon-chevron"] {
    background: url('/themes/default-bootstrap/dist/images/icons.svg') no-repeat -387px -16px;
    width: 7px;
    height: 11px;
    display: inline-block;
}

.icon-chevron-left {
    transform: scale(-1);
}

#pagination_previous_bottom {
    margin-right: 35px;
    @include breakpoint($xs) {
        margin-right: 10px;
    }
}

#pagination_next_bottom {
    margin-left: 35px;
    a{
        padding-left: 6px;
    }
    @include breakpoint($xs) {
        margin-left: 10px;
    }
}
#pagination_previous_bottom {

    i{
        margin-right: 2px;
    }

}

#pagination_next_bottom {
      i{
        margin-left: 2px;
    }

}


#pagination_bottom {
    display: flex;
    justify-content: center;
}

.pagination {
    width: auto !important;
}

select {
    height: 30px;
    border-radius: 4px;
    border: solid 1px #d7d7d7;
    padding-left: 3px;
    box-sizing: border-box;
    padding-top: 3px;
}
.ajax_block_product {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;

     
        
    
    
    @include breakpoint($sm) {
        flex-basis: 33.333%;
        max-width: 33.333%;
    }

    @include breakpoint(xs) {
        flex-basis: 50%;
        max-width: 50%;
        padding-left: 5px;
        padding-right: 5px;
    }
}


#pagination_bottom {
    .pagination {
        margin-bottom: 40px;
    }
}

.rb-pro-att {
    min-height: 45px;
}

body{
    @media screen and (max-width: 768px){
        .pagination li span, .pagination ul li span {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 375px){
        .pagination li span, .pagination ul li span {
            font-size: 13px;
        }
    }

}
